import React from "react";
import { Table, TableRow, TableHead, TableBody, TableCell, } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  sortArrow: {
    width: "15.2px",
    position: "absolute",
    marginLeft: "5px",
  },
});

function TableComponent(props) {
  let tz = new Date().getTimezoneOffset();

  let { data, offset, classes, } = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#8093FE" }}>
            <TableRow>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>S. No.</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}> Package Name</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}> Package Maximum</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}> Description</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Status</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Approved On</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.packageId ? tempdata.packageId.packName : '-'}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.packageId ? tempdata.packageId.max : 0}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.packageId ? tempdata.packageId.desc : '-'}
                </TableCell>
                <TableCell style={{ color: "#898CAF" }}>{tempdata && tempdata.isApprove === true ?
                  <h4
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                  >
                    Approved
                  </h4> : <Button
                    color="primary"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}

                  >
                    Pending
                  </Button>}</TableCell>
                {tempdata.updatedAt ?
                  <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                    {moment(new Date(parseInt(tempdata.updatedAt))).utc(tz).format("MMMM Do YYYY ")}
                  </TableCell> :
                  <TableCell className="pl-3 fw-normal">
                    -
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
