import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddInternalStaffDialog from "./components/AddInternalStaffDialog/AddInternalStaffDialog";
import UpdateInternalStaffDialog from "./components/UpdateInternalStaffDialog/UpdateInternalStaffDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { staffActions, landlordActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

class InternalStaffManagement extends Component {
  constructor(props) {
    document.title = "ATS | Administrator";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSort = this.handleSort.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatepwdhandleChangeInput =
      this.updatepwdhandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatePwdhandleClose = this.updatePwdhandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.updatePwdhandleOpenCreateModal =
      this.updatePwdhandleOpenCreateModal.bind(this);

    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      sortBy: "landlord",
      sortOrder: "1",
      staffCreateModal: false,
      updatestaffCreateModal: false,
      updatePasswordCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        address: "",
        contact: "",
        roll_id: "",
        parentId: "",
      },
      updateformData: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        contact: "",
        parentId: "",
      },
      updatePwdformData: {
        id: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 3,
      size: this.state.size,
    };
    this.props.dispatch(staffActions.getInternalStaffByLandLord(data));
  }

  componentDidUpdate() {
    this.setState((prevState) => { });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.internalstaff.addStaffSuccess) {
      return {
        ...nextProps,
        formData: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          address: "",
          contact: "",
          roll_id: "",
          parentId: "",
        },
        staffCreateModal: false,
        updatestaffCreateModal: false,
        updatePasswordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      roll_id: 3,
      size: this.state.size,
    };
    this.props.dispatch(staffActions.getInternalStaffByLandLord(data));
  };

  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      roll_id: 3,
      size: this.state.size,
    };
    this.props.dispatch(staffActions.getInternalStaffByLandLord(data));
  }

  handleOpenCreateModal() {
    this.setState({ staffCreateModal: true });
  }

  updatePwdhandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatePasswordCreateModal: true, updatePwdformData: data });
  }

  updatehandleOpenCreateModal(data) {
    this.setState({ updatestaffCreateModal: true, updateformData: data });
  }

  handleClose() {
    this.setState({ staffCreateModal: false });
  }

  updatehandleClose() {
    this.setState({ updatestaffCreateModal: false });
  }
  updatePwdhandleClose() {
    this.setState({ updatePasswordCreateModal: false });
  }

  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };

  handleChangeInput = (event) => {
    console.log("eventeventeventevent", event);
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    console.log(event.target.name, event.target.value);
    this.setState({ formData });
  };

  updatehandleChangeInput = (event) => {
    const { name, value } = event.target;
    const { updateformData } = this.state;
    if (name === "parentId") {
      updateformData["landlord"] = {
        id: value,
      };
    }
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };

  updatepwdhandleChangeInput = (event) => {
    console.log("eventeventeventevent", event.target.value);
    const { updatePwdformData } = this.state;
    updatePwdformData[event.target.name] = event.target.value;
    this.setState({ updatePwdformData });
  };

  handleSubmit = () => {
    let reqData = {
      first_name: this.state.formData.first_name,
      last_name: this.state.formData.last_name,
      email: this.state.formData.email,
      password: this.state.formData.password,
      confirmPassword: this.state.formData.confirmPassword,
      address: this.state.formData.address,
      contact: this.state.formData.contact,
      roll_id: 3,
    };
    this.props.dispatch(staffActions.addStaffLandlord(reqData));
  };

  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      first_name: this.state.updateformData.first_name,
      last_name: this.state.updateformData.last_name,
      address: this.state.updateformData.address,
      contact: this.state.updateformData.contact,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(staffActions.updateStaffLandlord(reqData));
  };
  updatepwdhandleSubmit = () => {
    let reqData = {
      id: this.state.updatePwdformData.id,
      password: this.state.updatePwdformData.password,
    };
    console.log("reqDatareqaupdatepwdhandleSubmit  ", reqData);

    this.props.dispatch(staffActions.updatePasswordLandlord(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      staffActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  handleSort(event) {
    let sortOrder = this.state.sortOrder;
    sortOrder = sortOrder === "-1" ? "1" : sortOrder === "1" ? "" : "-1";

    this.setState({ page: 1, sortOrder, sortBy: event });
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 3,
      size: this.state.size,
      sortOrder: this.state.sortOrder,
      sortBy: this.state.sortBy
    };
    if (sortOrder === "-1" || sortOrder === "1") {
      data["sortOrder"] = sortOrder;
      data["sortBy"] = event;
    }

    this.props.dispatch(staffActions.getInternalStaffByLandLord(data));
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      roll_id: 3,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(staffActions.deleteStaff(tempdata));
  }
  onDisable(data) {
    let tempdata = {
      resourceId: data.id,
      resourceType: "user",
      value: !data.active,
    };
    this.props.dispatch(staffActions.disableStaffLandlord(tempdata));
  }
  render() {
    let { internalstaff, landlords, classes } = this.props;
    let { items, total, itemsLandlord, totalLandlord } = internalstaff;
    console.log("RENDER__itemsLandlord:::://///", items, total);
    return (
      <>
        <PageTitle title="Internal Staff" />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container>
                <Grid item sm />
                <Grid>
                  <TextField
                    id="standard-search"
                    label="Search Name..."
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    // className={classes.textField}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    // className={classes.button}
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    sortOrder={this.state.sortOrder}
                    sortBy={this.state.sortBy}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    handleSort={this.handleSort}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    updatePwdhandleOpenCreateModal={
                      this.updatePwdhandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddInternalStaffDialog
          staffCreateModal={this.state.staffCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
          landlords={itemsLandlord}
        />
        <UpdateInternalStaffDialog
          updateuserCreateModal={this.state.updatestaffCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
          landlords={itemsLandlord}
        />
        <UpdatePasswordDialog
          updateuserCreateModal={this.state.updatePasswordCreateModal}
          classes={classes}
          updatePwdformData={this.state.updatePwdformData}
          updatepwdhandleSubmit={this.updatepwdhandleSubmit}
          updatepwdhandleChangeInput={this.updatepwdhandleChangeInput}
          updatePwdhandleClose={this.updatePwdhandleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { internalstaff, landlords } = state;
  return {
    internalstaff,
    landlords,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(InternalStaffManagement)),
);

