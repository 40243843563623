import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const archiveService = {
  //   login,
  //   getContractTypeList,
  logout,
  //   addArchive,
  //   uploadImage,
  getArchiveList,
  //   disableArchive,
  //   updateArchive,
  //   deleteArchive,
  //   getAllArchive,
  //   getDesignationList,
  //   getJobList,
  //   getLocationList,
  //   getJobTypeList,
  //   getWorkerTypeList,
  getJobDetails,
  getTimeData,
};
function logout() {
  localStorage.removeItem("archive");
}
// function login(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
//     .then(handleResponse)
//     .then((archive) => {
//       let archiveObj = {
//         archiveinfo: archive.data,
//       };
//       if (archive.data) {
//         localStorage.setItem("archive", JSON.stringify(archive.data));
//       }

//       return archiveObj;
//     });
// }
function getTimeData(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/inAndOutTime`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let archiveObj = {
        timeData: data.data,
      };
      //   console.log("i am in service Gourav", archiveObj);

      return archiveObj;
    });
}
function getArchiveList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getFilteredJobList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let archiveObj = {
        getArchiveList: data.data,
      };
        console.log("i am in service Gourav", archiveObj);

      return archiveObj;
    });
}

function getJobDetails(id) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "GET",
    headers: header,
    // body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getjob/${id}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log("data121212", data);
      let workerWorkDetails = {
        getWorkerDetails: data.data,
      };
      return workerWorkDetails;
    });
}
// function getAllArchive() {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//   };
//   return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let archiveObj = {
//         getAllArchive: data.data,
//       };
//       console.log();

//       return archiveObj;
//     });
// }
// function addArchive(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/createJob`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let archiveObj = {
//         addArchive: data.data,
//       };
//       console.log();

//       return archiveObj;
//     });
// }
// function updateArchive(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL + `/updateArchive`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let archiveObj = {
//         addArchive: data.data,
//       };
//       return archiveObj;
//     });
// }
// function disableArchive(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let archiveObj = {
//         addArchive: data.data,
//       };
//       console.log();

//       return archiveObj;
//     });
// }
// function deleteArchive(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL + `/deleteArchive`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let archiveObj = {
//         addArchive: data.data,
//       };
//       console.log();

//       return archiveObj;
//     });
// }
// function uploadImage(filedata) {
//   let header = new Headers({
//     Authorization: authHeader().Authorization,
//   });
//   var data = new FormData();
//   data.append("image", filedata);

//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: data,
//   };
//   return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
//     .then(handleResponse)
//     .then((res) => {
//       let archiveObj = {
//         filesDetails: res.data,
//       };
//       return archiveObj;
//     });
// }
function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

// function getJobTypeList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getJobTypeList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let jobtypeObj = {
//         getJobTypeList: data.data,
//       };
//       console.log("i am in service getJobTypeList :::", jobtypeObj);

//       return jobtypeObj;
//     });
// }
// function getContractTypeList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getUserList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let jobtypeObj = {
//         getContractTypeList: data.data,
//       };
//       console.log("i am in service getJobTypeList :::", jobtypeObj);

//       return jobtypeObj;
//     });
// }
// function getWorkerTypeList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getWorkerTypeList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let jobtypeObj = {
//         getWorkerTypeList: data.data,
//       };
//       // console.log("i am in service getJobTypeList :::", jobtypeObj);

//       return jobtypeObj;
//     });
// }

// function getLocationList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getLocationList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let jobObj = {
//         getLocationList: data.data,
//       };
//       console.log("i am in service getLocationList :::", jobObj);

//       return jobObj;
//     });
// }
// function getJobList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getJobList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let jobObj = {
//         getJobList: data.data,
//       };
//       console.log("i am in service getJobList :::", jobObj);

//       return jobObj;
//     });
// }

// function getDesignationList(data) {
//   let header = new Headers({
//     "Content-Type": "application/json",
//     Authorization: authHeader().Authorization,
//   });
//   const requestOptions = {
//     method: "POST",
//     headers: header,
//     body: JSON.stringify(data),
//   };
//   return fetch(CONST.BACKEND_URL2 + `/getDesignationList`, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       let designationObj = {
//         getDesignationList: data.data,
//       };
//       console.log("i am in service getDesignationList :::", designationObj);

//       return designationObj;
//     });
// }
