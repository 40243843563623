import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function ViewContractorLandlordDialog(props) {
  let {
    classes,
    // formData,
    jobCreateModal,
    handleClose,
    // handleViewClose,
    viewRowData,
    // data
  } = props;

  return (
    <Dialog
      open={jobCreateModal}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      {/* <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Contractor Details
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar> */}

      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={handleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>

      <div className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom>
          <u>Contractor Details</u>
        </Typography>

        <div className={classes.paper}>
          <Typography style={{ fontSize: '17px' }} component="div" variant="body1">
            <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <b>Contact Name:</b> {viewRowData.contact_name}
            </Box>
            <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <b>Company Name:</b> {viewRowData.company_name}
            </Box>
            <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <b>Contact Number:</b> {viewRowData.contact}
            </Box>
            <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <b>Email:</b> {viewRowData.email}
            </Box>
            <br />
            <Box className="pl-3 fw-normal">
              <b>Address:</b> {viewRowData.address}
            </Box>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
}
