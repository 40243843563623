import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const locationLandlordService = {
  getAllLocationsLandlord,
  addLocationLandlord,
};

function getAllLocationsLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getLocationList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log("Gufran", data);
      let locationslandlordObj = {
        getAllLocationsLandlord: data.data,
      };
      locationslandlordObj.getAllLocationsLandlord.list =
        locationslandlordObj.getAllLocationsLandlord.list.filter(
          (location) => location != null,
        );
      return locationslandlordObj;
    });
}

function addLocationLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/createLocation`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let locationLandlordObj = {
        addLocationLandlord: data.data,
      };
      return locationLandlordObj;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        //location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
