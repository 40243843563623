import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { sortItems } from "../../../../_helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 1024,
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    keyWord,
    classes,
    handleOpenViewCreateModal,
    // onDeleteClick,
    // onDisable
  } = props;
  let sortedData = {};

  // console.log("datadatadatadata_______-!", data);

  if (data) {
    sortedData = sortItems("asc", "city", data);
  }


  console.log("sortedDatasortedDatasortedDatasortedData_______-!", sortedData);

  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#8093FE" }}>
            <TableRow>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>S. No.</TableCell>
              <TableCell style={{ width: "25%", color: "#fff", fontWeight: 700 }}>Address</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>City</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Postal/Zip Code</TableCell>
              <TableCell style={{ width: "20%", color: "#fff", fontWeight: 700 }}>Province/State</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((tempdata, index) => (
              <TableRow key={tempdata.id} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {`${tempdata.linecomplete} `}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.city}
                </TableCell>

                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>{tempdata.pin}</TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.state}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleOpenViewCreateModal(tempdata)}
                  >
                    More Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
