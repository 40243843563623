import axios from "axios";
import { CONST } from "../_config";
import { authHeader } from "../_helpers";

export const fileService = {
  uploadFiles,
  deleteFiles,
  downloadFileURL,
  downloadFile,
};

const header = {
  Authorization: authHeader().Authorization,
};

function uploadFiles(formData) {
  return axios.post(CONST.BACKEND_URL + "/upload/siteInfo", formData, {
    headers: header,
    onUploadProgress: (progressEvent) => {
      console.log("progressEvent", progressEvent);
      console.log(
        "Upload progress",
        Math.floor((progressEvent.loaded / progressEvent.total) * 100) + "%",
      );
    },
  });
}

function deleteFiles(requestData) {
  return axios.post(CONST.BACKEND_URL + "/siteInfo", requestData, {
    headers: header,
  });
}

function downloadFile(fileName) {
  return axios({
    url: CONST.FILE_URL + "/images1/" + fileName,
    headers: header,
    method: "GET",
    responseType: "blob",
    "Content-Type": "application/pdf",
  });
}

function downloadFileURL(fileName) {
  return axios({
    url: fileName,
    headers: header,
    method: "GET",
    responseType: "blob",
    "Content-Type": "application/pdf",
  });
}
