export const workerConstants = {
    LOGIN_REQUEST: 'WORKERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'WORKERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'WORKERS_LOGIN_FAILURE',

    LOGOUT: 'WORKERS_LOGOUT',

    GETALL_REQUEST: 'WORKERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'WORKERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'WORKERS_GETALL_FAILURE',

    GET_ALL_WORKER_REQUEST: 'WORKERS_GET_ALL_WORKER_REQUEST',
    GET_ALL_WORKER_SUCCESS: 'WORKERS_GET_ALL_WORKER_SUCCESS',
    GET_ALL_WORKER_FAILURE: 'WORKERS_GET_ALL_WORKER_FAILURE',
    
    GETALL_WORKER_NOTIFY_REQUEST: 'GETALL_WORKER_NOTIFY_REQUEST',
    GETALL_WORKER_NOTIFY_SUCCESS: 'GETALL_WORKER_NOTIFY_SUCCESS',
    GETALL_WORKER_NOTIFY_FAILURE: 'GETALL_WORKER_NOTIFY_FAILURE',

    UPDATE_WORKER_NOTIFY_REQUEST: 'UPDATE_WORKER_NOTIFY_REQUEST',
    UPDATE_WORKER_NOTIFY_SUCCESS: 'UPDATE_WORKER_NOTIFY_SUCCESS',
    UPDATE_WORKER_NOTIFY_FAILURE: 'UPDATE_WORKER_NOTIFY_FAILURE',


    ADD_RESTAURANT_WORKER_REQUEST: 'ADD_RESTAURANT_WORKER_REQUEST',
    ADD_RESTAURANT_WORKER_SUCCESS: 'ADD_RESTAURANT_WORKER_SUCCESS',
    ADD_RESTAURANT_WORKER_FAILURE: 'ADD_RESTAURANT_WORKER_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    UPDATE_WORKER_PASSWORD_REQUEST: 'UPDATE_WORKER_PASSWORD_REQUEST',
    UPDATE_WORKER_PASSWORD_SUCCESS: 'UPDATE_WORKER_PASSWORD_SUCCESS',
    UPDATE_WORKER_PASSWORD_FAILURE: 'UPDATE_WORKER_PASSWORD_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
