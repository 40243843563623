import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ListAltRounded,
  PaymentRounded,
  RedeemTwoTone,
  PeopleAltRounded,
  WorkOutlineRounded,
  ChromeReaderModeRounded,
  ArrowBack as ArrowBackIcon,
  LocationOnOutlined,
  Archive,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
//import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: "Landlords",
    link: "/app/landlord",
    icon: <ListAltRounded />,
  },
  {
    id: 2,
    label: "Contractors",
    link: "/app/contractor",
    icon: <PaymentRounded />,
  },
  {
    id: 3,
    label: "Workers",
    link: "/app/worker",
    icon: <PeopleAltRounded />,
  },
  {
    id: 4,
    label: "Internal Staff",
    link: "/app/internalStaff",
    icon: <ChromeReaderModeRounded />,
  },
  {
    id: 5,
    label: "Jobs",
    link: "/app/job",
    icon: <WorkOutlineRounded />,
  },
  // {
  //   id: 7,
  //   label: "JobTypes",
  //   link: "/app/jobType",
  //   icon: <RedeemTwoTone />
  // },
  {
    id: 8,
    label: "Designation",
    link: "/app/designation",
    icon: <RedeemTwoTone />,
  },
  {
    id: 9,
    label: "Package",
    link: "/app/packageManagement",
    icon: <ListAltRounded />
  },
  {
    id: 10,
    label: "User Package",
    link: "/app/userPackage",
    icon: <ListAltRounded />
  },
  {
    id: 11,
    label: "Internal Staff Package",
    link: "/app/internalStaffPackage",
    icon: <ListAltRounded />
  }
];

const landlordstructure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/landlordDashboard",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: "Jobs",
    link: "/app/jobLandlord",
    icon: <WorkOutlineRounded />,
  },
  {
    id: 2,
    label: "Locations",
    link: "/app/locationLandlord",
    icon: <LocationOnOutlined />,
  },
  {
    id: 3,
    label: "Contractors",
    link: "/app/contractorLandlord",
    icon: <PaymentRounded />,
  },
  {
    id: 4,
    label: "Landlord InternalStaff",
    link: "/app/internalStaffLandlord",
    icon: <ChromeReaderModeRounded />,
  },
  {
    id: 5,
    label: "Archive",
    link: "/app/archives",
    icon: <Archive />,
  },
  {
    id: 6,
    label: "Package Management",
    link: "/app/internalstaffpackagemanagement",
    icon: <ChromeReaderModeRounded />,
  },
  // {
  //   id: 8,
  //   label: "Package",
  //   link: "/app/packageManagement",
  //   icon: <ListAltRounded />
  // }
  // {
  //   id: 4,
  //   label: "InternalStaffLandlord",
  //   link: "/app/internalStaffLandlord",
  //   icon: <ChromeReaderModeRounded />,
  // },
];

function Sidebar({ user, location }) {
  var classes = useStyles();
  var theme = useTheme();
  // console.log("user ';;';';';';';'; asif  ",user);
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {user && user.roll_id === 0
          ? landlordstructure.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))
          : structure.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    users,
    user,
  };
}
export default connect(mapStateToProps)(withRouter(Sidebar));
