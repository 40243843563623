//import { createMuiTheme} from "@material-ui/core/styles";

//const theme = createMuiTheme();
const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icons: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    margin: "10px 5px",
    // "&:hover": { backgroundColor: "#000000885" },
  },
  iconSpace: {
    margin: "10px 10px",
  },
});

export default styles;
