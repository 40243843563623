import React, { useState, useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Select,
  Typography,
  FormLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import DatePicker, { } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Button } from "../../../../components/Wrappers/Wrappers";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select2 from 'react-select';

const format = "YYYY-MM-DD";

export default function AddJobLandlordDialog(props) {
  let {
    classes,
    formData,
    userCreateModal,
    locationData,
    handleClose,
    handleChangeInput,
    handleChangeInputLocation,
    handleChangeInputDesignation,
    handleChangeInputContractor,
    handleChangeInputInternal,
    handleSubmit,
    jobTypeItems,
    locationListitems,
    designationListitems,
    contractItems,
    itemsJob
    // handleDateChange,
  } = props;
  // console.log("contractItems____________contractItems", contractItems);

  const [dates, setDates] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [assignToId, setAssignToId] = useState("CONTRACTOR");
  // const [datePickerDisable, setDatePickerDisable] = useState(false);
  let locationListitemsTEmp = locationListitems.map((element) => ({ value: element.id, label: element.line1 + " " + element.line2 + " " + element.line3 + " " + element.city + " " + element.state }));
  let designationListitemsTemp = designationListitems.map((element) => ({ value: element.id, label: element.name }));
  let contractorListitemsTemp = contractItems.map((element) => ({
    value: element.id, label: element.company_name
    // element.first_name + " " + element.last_name 
  }));
  let internalListitemsTemp = itemsJob && itemsJob.length ? itemsJob.map((element) => ({ value: element.id, label: element.first_name + " " + element.last_name })) : []
  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      setDates([]);
      if (formData.jobType_Id === "6019c8929fba3c2d3b9dad25") {
        onDateReset();
        setShowDate(false);
      } else setShowDate(true);
    };
  }, [formData.jobType_Id]);





  const onDateReset = () => {
    setDates([]);
  };
  console.log("onDateReset", dates);

  return (
    <Dialog open={userCreateModal} onClose={() => handleClose(onDateReset)}>
      {/* <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(onDateReset)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Job
          </Typography>
          <Button color="inherit" onClick={() => handleClose(onDateReset)}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar> */}

      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={() => handleClose(onDateReset)} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Job
          </Typography>

          <ValidatorForm
            onSubmit={() => {
              handleSubmit(dates, onDateReset);
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Job Title*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="job_title"
                  id="job_title"
                  value={formData.job_title}
                  validators={["required"]}
                  errorMessages={["Job Title is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Job Details*"
                  fullWidth
                  className="MultiLine with rows: 2 and rowsMax: 4"
                  onChange={handleChangeInput}
                  name="job_details"
                  id="job_details"
                  value={formData.job_details}
                  validators={["required"]}
                  multiline rows={2}
                  maxRows={4}
                  col={4}
                  errorMessages={["Job Details is required"]}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%" }}>
                  <SelectValidator
                    fullWidth
                    label="Job Type*"
                    name="jobType_Id"
                    id="jobType_Id"
                    value={formData.jobType_Id}
                    onChange={handleChangeInput}
                    labelId="select-jobtype-label"
                    validators={["required"]}
                    errorMessages={["Job Type is required"]}
                  >
                    {jobTypeItems.map((element) => (
                      <MenuItem value={element.id}>{element.name}</MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Grid>
              {showDate && formData.jobType_Id && (
                <div>
                  <Grid item xs={12} sm={12}>
                    <FormLabel style={{ marginRight: "120px" }}>
                      Select Dates
                    </FormLabel>

                    <DatePicker
                      value={dates}
                      onChange={setDates}
                      multiple
                      sort
                      format={format}
                      calendarPosition="bottom-center"
                      plugins={[<DatePanel />]}
                    />
                  </Grid>
                  <Grid>
                    <ul>
                      {dates.map((date, index) => (
                        <li key={index}>{date.format()}</li>
                      ))}
                    </ul>
                    <Button
                      variant="text"
                      color="primary"
                      type="button"
                      // className={classes.submit}
                      onClick={() => {
                        onDateReset();
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </div>
              )}

              <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}> Location </label>
                <Select2
                  width="full"
                  name="location_id"
                  id="location_id"
                  onChange={handleChangeInputLocation}
                  value={locationListitemsTEmp.filter(function (option) {
                    return option.value === formData.location_id;
                  })}
                  options={locationListitemsTEmp}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
                />
              </Grid>


              <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Designation</label>
                <Select2
                  width="full"
                  name="designation_Id"
                  id="designation_Id"
                  onChange={handleChangeInputDesignation}
                  value={designationListitemsTemp.filter(function (option) {
                    return option.value === formData.designation_Id;
                  })}
                  options={designationListitemsTemp}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%" }}>
                  <SelectValidator
                    fullWidth
                    label="Assign To"
                    name="assignToId"
                    value={formData.assignToId}
                    onChange={handleChangeInput}
                    validators={["required"]}
                  >
                    <MenuItem value={"CONTRACTOR"}>{"Contractor"}</MenuItem>
                    <MenuItem value={"IS"}>{"Internal Staff"}</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid>


              {
                formData.assignToId === "CONTRACTOR" ?
                  <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                    <label htmlFor="resourceList" style={{ color: 'grey' }}>Contractor</label>
                    <Select2
                      width="full"
                      name="assign_to"
                      id="assign_to"
                      onChange={handleChangeInputContractor}
                      value={contractorListitemsTemp.filter(function (option) {
                        return option.value === formData.assign_to;
                      })}
                      options={contractorListitemsTemp}
                      className="basic-multi-select width: 'full'"
                      classNamePrefix="Activities"
                    />
                  </Grid>

                  :

                  <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                    <label htmlFor="resourceList" style={{ color: 'grey' }}>Internal Staff</label>
                    <Select2
                      width="full"
                      isMulti
                      name="internalId"
                      id="internalId"
                      onChange={handleChangeInputInternal}
                      options={internalListitemsTemp}
                      className="basic-multi-select width: 'full'"
                      classNamePrefix="Activities"
                    />
                  </Grid>

              }
              <Grid item xs={12}></Grid>
            </Grid>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
              style={{ marginBottom: "5px" }}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
