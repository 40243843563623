import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function UpdatePasswordDialog(props) {

  let { classes, updatePwdformData, updateuserCreateModal, updatepwdhandleChangeInput, updatepwdhandleSubmit,updatePwdhandleClose } = props;

  return (
    <Dialog fullScreen open={updateuserCreateModal} onClose={updatePwdhandleClose} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={updatePwdhandleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update Password
      </Typography>
          <Button color="inherit" onClick={updatePwdhandleClose}>
            Cancel
      </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Password
        </Typography>

          <ValidatorForm
            onSubmit={updatepwdhandleSubmit}
          >
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="password*"
                  id="password"
                  fullWidth
                  onChange={updatepwdhandleChangeInput}
                  name="password"
                  value={updatePwdformData.password}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              
             
              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
          </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
