import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewLocationLandlordDialog from "./components/ViewLocationLandlordDialog/ViewLocationLandlordDialog";
// import AddLocationLandlordDialog from "./components/AddLocationLandlordDialog/AddLocationLandlordDialog";
import UpdateLocationLandlordDialog from "./components/UpdateLocationLandlordDialog/UpdateLocationLandlordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { locationLandlordActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";

import axios from "axios";
import { CONST } from "../../_config";
import { authHeader } from "../../_helpers";

const theme = createMuiTheme();

class LocationLandlordManagement extends Component {
  constructor(props) {
    document.title = "ATS | LandLord | Location";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddClose = this.handleAddClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.handleOpenViewCreateModal = this.handleOpenViewCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      siteInfoData: {},
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      ViewlocationLandlordCreateModal: false,
      locationLandlordCreateModal: false,
      updateLocationLandlordCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        line1: "",
        line2: "",
        line3: "",
        city: "",
        pin: "",
        latitude: "",
        longitude: "",
        state: "",
      },
      updateformData: {
        line1: "",
        line2: "",
        line3: "",
        city: "",
        pin: "",
        latitude: "",
        longitude: "",
        state: "",
      },
    };
  }
  componentDidMount() {
    // let data = {
    //   keyWord: "",
    //   pageNo: 1,
    //   size: this.state.size,
    // };
    this.props.dispatch(locationLandlordActions.getAllLocationsLandlord());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.locationlandlords.addLocationLandlordSuccess) {
      return {
        ...nextProps,
        formData: {
          line1: "",
          line2: "",
          line3: "",
          city: "",
          pin: "",
          latitude: "",
          longitude: "",
          state: "",
        },
        locationLandlordCreateModal: false,
        updateLocationLandlordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      size: this.state.size,
    };
    this.props.dispatch(locationLandlordActions.getAllLocationsLandlord(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      // pageNo: 1,
      // size: this.state.size,
    };
    this.props.dispatch(locationLandlordActions.getAllLocationsLandlord(data));
  }
  handleOpenCreateModal() {
    this.setState({ locationLandlordCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({
      updateLocationLandlordCreateModal: true,
      updateformData: data,
    });
  }

  handleOpenViewCreateModal(data) {

    console.log("table k row se kya data aa rha h:::", data);

    this.setState({ ViewlocationLandlordCreateModal: true, viewRowData: data });

    this.locationHandler(data)
  }


  locationHandler = (location, reload) => {
    if (reload) {
      // setLoading(true);
    }
    // setLocation(location);
    axios
      .post(
        CONST.BACKEND_URL2 + "/getSiteInfo",
        { locationId: location.id },
        {
          headers: {
            Authorization: authHeader().Authorization,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        if (res.data.message) {
          this.setState({ siteInfoData: res.data.message })
          // setSiteInfoData(res.data.message);
        }
      })
      .catch((err) => {
        console.log("errrrrrr", err);
        // setLoading(false);
      });
  };

  handleClose() {
    this.setState({ ViewlocationLandlordCreateModal: false });
  }
  handleAddClose() {
    this.setState({ locationLandlordCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updateLocationLandlordCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };
  handleSubmit = () => {
    let reqData = {
      line1: this.state.formData.line1,
      line2: this.state.formData.line2,
      line3: this.state.formData.line3,
      city: this.state.formData.city,
      pin: this.state.formData.pin,
      coordinates: [
        this.state.formData.latitude,
        this.state.formData.longitude,
      ],
      state: this.state.formData.state,
    };
    console.log("reqData  ", this.state.formData);
    this.props.dispatch(locationLandlordActions.addLocationLandlord(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      // id: this.state.updateformData.id,
      // firstName: this.state.updateformData.firstName,
      // email: this.state.updateformData.email,
      // lastName: this.state.updateformData.lastName,
      // gender: this.state.updateformData.gender,
      // dob: this.state.updateformData.dob,
      // doj: this.state.updateformData.doj,
      // mobile: this.state.updateformData.mobile,
      // address: this.state.updateformData.address,
      // height: this.state.updateformData.height,
      // weight: this.state.updateformData.weight,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(locationLandlordActions.updateJobLandlord(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      locationLandlordActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(locationLandlordActions.deleteJobLandlord(tempdata));
  }
  onDisable(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    this.props.dispatch(locationLandlordActions.disableJobLandlord(tempdata));
  }
  render() {
    let { locationlandlords, classes } = this.props;
    let { items, total } = locationlandlords;
    return (
      <>
        <PageTitle title="Locations" />
        <Grid container>

          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                {/* <Grid item sm /> */}
                <Grid>
                  <TextField
                    id="standard-search"
                    label="Search location"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    // className={classes.textField}
                    margin="normal"
                  />
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    // className={classes.button}
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                  >
                    Add
                  </Button> */}
                </Grid>
              </Grid>
              <Grid container></Grid>
              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    offset={this.state.offset}
                    keyWord={this.state.keyWord}
                    onRowClick={this.onRowClick}
                    handleOpenViewCreateModal={this.handleOpenViewCreateModal}
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {/* {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null} */}
            </Widget>
          </Grid>
        </Grid>

        <ViewLocationLandlordDialog
          jobCreateModal={this.state.ViewlocationLandlordCreateModal}
          classes={classes}
          viewRowData={this.state.viewRowData}
          siteInfoData={this.state.siteInfoData}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { locationlandlords } = state;
  return {
    locationlandlords,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(LocationLandlordManagement)),
);
