import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    classes,
    // updatehandleOpenCreateModal,
    handleOpenCreateModal,
    // onDeleteClick,
    // onDisable
  } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  console.log("data", data);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Job Id</TableCell>
              <TableCell>Landlord</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Action</TableCell>
              {/* <TableCell >RATING</TableCell> */}
              {/* <TableCell >MOBILE</TableCell> */}
              {/* <TableCell style={{textAlign:'center'}}>ACTION</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((tempdata, index) => (
                <TableRow key={index}>
                  <TableCell className="pl-3 fw-normal">
                    {offset + index + 1}
                  </TableCell>
                  <TableCell className="pl-3 fw-normal">
                    {tempdata.job_title}
                  </TableCell>
                  <TableCell className="pl-3 fw-normal">
                    <span
                      style={{
                        textDecoration:
                          tempdata.status === 2 ? "underline" : "none",
                      }}
                    >
                      {tempdata.job_id}
                    </span>
                  </TableCell>
                  <TableCell className="pl-3 fw-normal">
                    {tempdata.created_by && tempdata.created_by.company_name
                      ? tempdata.created_by.company_name
                      : "-"}
                  </TableCell>
                  <TableCell className="pl-3 fw-normal">
                    {tempdata.designation_Id && tempdata.designation_Id.name
                      ? tempdata.designation_Id.name
                      : "-"}
                  </TableCell>
                  <TableCell className="pl-3 fw-normal">
                    {tempdata.location_id
                      ? `${tempdata.location_id.linecomplete} `
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="success"
                      size="small"
                      className="px-2"
                      variant="contained"
                      onClick={() => handleOpenCreateModal(tempdata)}
                    >
                      Job Details
                    </Button>
                  </TableCell>

                  {/* <Button
                      color={tempdata.isDisable ? "warning" : "primary"}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDisable(tempdata)}
                    >
                      {tempdata.isDisable ? 'Disable' : 'Enable'}
                    </Button> */}
                  {/* <Button
                      color="secondary"
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: '5px' }}
                      onClick={() => onDeleteClick(tempdata)}
                    >
                      Delete
              </Button> */}
                  {/* </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
