export const packageConstants = {
    LOGIN_REQUEST: 'PACKAGES_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'PACKAGES_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'PACKAGES_LOGIN_FAILURE',

    LOGOUT: 'PACKAGES_LOGOUT',

    GETALL_REQUEST: 'PACKAGES_GETALL_REQUEST',
    GETALL_SUCCESS: 'PACKAGES_GETALL_SUCCESS',
    GETALL_FAILURE: 'PACKAGES_GETALL_FAILURE',


    GET_USER_PACK_LIST_REQUEST: 'GET_USER_PACK_LIST_REQUEST',
    GET_USER_PACK_LIST_SUCCESS: 'GET_USER_PACK_LIST_SUCCESS',
    GET_USER_PACK_LIST_FAILURE: 'GET_USER_PACK_LIST_FAILURE',

    GETALL_PACK_FOR_LAND_REQUEST: 'GETALL_PACK_FOR_LAND_REQUEST',
    GETALL_PACK_FOR_LAND_SUCCESS: 'GETALL_PACK_FOR_LAND_SUCCESS',
    GETALL_PACK_FOR_LAND_FAILURE: 'GETALL_PACK_FOR_LAND_FAILURE',

    RECENT_USER_PACK_REQUEST: 'RECENT_USER_PACK_REQUEST',
    RECENT_USER_PACK_SUCCESS: 'RECENT_USER_PACK_SUCCESS',
    RECENT_USER_PACK_FAILURE: 'RECENT_USER_PACK_FAILURE',

    GET_CURRENT_USER_PACK_REQUEST: 'GET_CURRENT_USER_PACK_REQUEST',
    GET_CURRENT_USER_PACK_SUCCESS: 'GET_CURRENT_USER_PACK_SUCCESS',
    GET_CURRENT_USER_PACK_FAILURE: 'GET_CURRENT_USER_PACK_FAILURE',

    GET_ALL_PACKAGE_REQUEST: 'PACKAGES_GET_ALL_PACKAGE_REQUEST',
    GET_ALL_PACKAGE_SUCCESS: 'PACKAGES_GET_ALL_PACKAGE_SUCCESS',
    GET_ALL_PACKAGE_FAILURE: 'PACKAGES_GET_ALL_PACKAGE_FAILURE',

    GETALL_PACKAGE_NOTIFY_REQUEST: 'GETALL_PACKAGE_NOTIFY_REQUEST',
    GETALL_PACKAGE_NOTIFY_SUCCESS: 'GETALL_PACKAGE_NOTIFY_SUCCESS',
    GETALL_PACKAGE_NOTIFY_FAILURE: 'GETALL_PACKAGE_NOTIFY_FAILURE',

    UPDATE_PACKAGE_NOTIFY_REQUEST: 'UPDATE_PACKAGE_NOTIFY_REQUEST',
    UPDATE_PACKAGE_NOTIFY_SUCCESS: 'UPDATE_PACKAGE_NOTIFY_SUCCESS',
    UPDATE_PACKAGE_NOTIFY_FAILURE: 'UPDATE_PACKAGE_NOTIFY_FAILURE',


    ADD_RESTAURANT_PACKAGE_REQUEST: 'ADD_RESTAURANT_PACKAGE_REQUEST',
    ADD_RESTAURANT_PACKAGE_SUCCESS: 'ADD_RESTAURANT_PACKAGE_SUCCESS',
    ADD_RESTAURANT_PACKAGE_FAILURE: 'ADD_RESTAURANT_PACKAGE_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    ADD_PACKAGE_REQUEST: 'ADD_PACKAGE_REQUEST',
    ADD_PACKAGE_SUCCESS: 'ADD_PACKAGE_SUCCESS',
    ADD_PACKAGE_FAILURE: 'ADD_PACKAGE_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    UPDATE_PACKAGE_PASSWORD_REQUEST: 'UPDATE_PACKAGE_PASSWORD_REQUEST',
    UPDATE_PACKAGE_PASSWORD_SUCCESS: 'UPDATE_PACKAGE_PASSWORD_SUCCESS',
    UPDATE_PACKAGE_PASSWORD_FAILURE: 'UPDATE_PACKAGE_PASSWORD_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

    GET_USER_PACKAGE_REQUEST: 'GET_USER_PACKAGE_REQUEST',
    GET_USER_PACKAGE_SUCCESS: 'GET_USER_PACKAGE_SUCCESS',
    GET_USER_PACKAGE_FAILURE: 'GET_USER_PACKAGE_FAILURE',


    GET_ALL_USER_PACKAGE_REQUEST: 'GET_ALL_USER_PACKAGE_REQUEST',
    GET_ALL_USER_PACKAGE_SUCCESS: 'GET_ALL_USER_PACKAGE_SUCCESS',
    GET_ALL_USER_PACKAGE_FAILURE: 'GET_ALL_USER_PACKAGE_FAILURE',

    GET_ALL_IS_PACKAGE_REQUEST: 'GET_ALL_IS_PACKAGE_REQUEST',
    GET_ALL_IS_PACKAGE_SUCCESS: 'GET_ALL_IS_PACKAGE_SUCCESS',
    GET_ALL_IS_PACKAGE_FAILURE: 'GET_ALL_IS_PACKAGE_FAILURE',

    //userPackage
    UPDATE_USER_PACKAGE_STATUS_REQUEST: 'UPDATE_USER_PACKAGE_STATUS_REQUEST',
    UPDATE_USER_PACKAGE_STATUS_SUCCESS: 'UPDATE_USER_PACKAGE_STATUS_SUCCESS',
    UPDATE_USER_PACKAGE_STATUS_FAILURE: 'UPDATE_USER_PACKAGE_STATUS_FAILURE',


    APPROVE_USER_PACKAGE_REQUEST: 'APPROVE_USER_PACKAGE_REQUEST',
    APPROVE_USER_PACKAGE_SUCCESS: 'APPROVE_USER_PACKAGE_SUCCESS',
    APPROVE_USER_PACKAGE_FAILURE: 'APPROVE_USER_PACKAGE_FAILURE',

    APPROVE_IS_PACKAGE_REQUEST: 'APPROVE_IS_PACKAGE_REQUEST',
    APPROVE_IS_PACKAGE_SUCCESS: 'APPROVE_IS_PACKAGE_SUCCESS',
    APPROVE_IS_PACKAGE_FAILURE: 'APPROVE_IS_PACKAGE_FAILURE',

    BUY_IS_PACKAGE_REQUEST: 'BUY_IS_PACKAGE_REQUEST',
    BUY_IS_PACKAGE_SUCCESS: 'BUY_IS_PACKAGE_SUCCESS',
    BUY_IS_PACKAGE_FAILURE: 'BUY_IS_PACKAGE_FAILURE',


    DELETE_USER_PACKAGE_REQUEST: 'DELETE_USER_PACKAGE_REQUEST',
    DELETE_USER_PACKAGE_SUCCESS: 'DELETE_USER_PACKAGE_SUCCESS',
    DELETE_USER_PACKAGE_FAILURE: 'DELETE_USER_PACKAGE_FAILURE',

};
