
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const packageService = {
    login,
    logout,
    addPackage,
    uploadImage,
    updatePassword,
    getPackageList,
    getUserPackageList,
    disablePackage,
    updatePackage,
    deletePackage,
    approvePackage,
    disableUserPackage,
    deleteUserPackage,
    getAllPackage,
    createPackage,
    getISPackageList,
    getMostRecentISPackByUserId,
    getPackageListForLand,
    getCurrentISPackageByUserId,
    getISPackageListByUser,
    buyISPackage,
    approveISPackage
};
function logout() {
    localStorage.removeItem('package');
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
        .then(handleResponse)
        .then(packages => {
            let packageObj = {
                packageinfo: packages.data
            }
            if (packages.data) {
                localStorage.setItem('packages', JSON.stringify(packages.data));
            }

            return packageObj;
        });
}

function updatePassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePassword`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updatePassword: data.data
            }
            console.log("I am in service", userObj);

            return userObj;
        });
}

function getPackageList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getPackageList: data.data
            }
            console.log("packageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObj", packageObj);

            return packageObj;
        });
}

function getPackageListForLand(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getPackageListForLand: data.data
            }
            console.log("packageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObj", packageObj);

            return packageObj;
        });
}

function getMostRecentISPackByUserId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getMostRecentISPackByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getMostRecentISPackByUserId: data.data
            }
            console.log("packageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObj", packageObj);

            return packageObj;
        });
}

function getCurrentISPackageByUserId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getCurrentISPackageByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getCurrentISPackageByUserId: data.data
            }
            console.log("packageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObj", packageObj);

            return packageObj;
        });
}

function getISPackageListByUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getISPackageListByUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getISPackageListByUser: data.data
            }
            console.log("packageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObjpackageObj", packageObj);

            return packageObj;
        });
}
function getUserPackageList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getUserPackageList: data.data
            }
            console.log("packageObjpackageObjpackageObjpacjpackageObjpackageObjpackag222222222222222j", packageObj);

            return packageObj;
        });
}
function getISPackageList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getISPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getISPackageList: data.data
            }
            console.log("packageObjpackageObjpackageObjpacjpackageObjpackageObjpackag222222222222222j", packageObj);

            return packageObj;
        });
}
function getAllPackage() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                getAllPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function addPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function createPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                addPackage: data.data
            }
            return packageObj;
        });
}
function updatePackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePackage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let packageObj = {
                addPackage: data.data
            }
            return packageObj;
        });
}
function disablePackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePackageStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function deletePackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
//userPackage   #############################################################
function approvePackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approvePackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function approveISPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approveISPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                approveISPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function buyISPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/buyISPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                buyISPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function disableUserPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserPackageStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function deleteUserPackage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUserPackage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let packageObj = {
                addPackage: data.data
            }
            console.log();

            return packageObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let packageObj = {
                filesDetails: res.data
            }
            return packageObj;
        });
}
function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}