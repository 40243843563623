import React, { Component } from "react";
import {
  Grid,
  //LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
} from "@material-ui/core";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
// import GoogleMap from "../../components/GoogleMap";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Category 1', value: 400 },
  { name: 'Category 2', value: 300 },

];

const COLORS = ['#8093FE', '#67C587'];
class Dashboard extends Component {
  constructor(props) {
    document.title = "ATS | Administrator";
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      markers: [],
      places: [],
      data: [
        { name: 'HVAC', value: 20 },
        { name: 'Electric', value: 35 },
        { name: 'Plumbing', value: 45 },
        { name: 'Roof', value: 15 },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatch(userActions.landlordStaticsData());
  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }

  render() {
    let classes = {};
    // const { places } = this.state;
    let { users } = this.props;
    let { staticsData, landlordStatsData } = users;
    return (
      <>
        <PageTitle title="" />
        {/* <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Landlords"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {staticsData ? staticsData.landloards : 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>

            <Widget style={{ backgroundColor: "#000" }}
              title="Total Contractors"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {landlordStatsData ? landlordStatsData.contractor : 0}
                </Typography>
              </div>
            </Widget>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Internal Staff"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {landlordStatsData ? landlordStatsData.internalStaff : 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: '#8093FE',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <div>
                <Typography size="22px" variant="h5" style={{ color: "#fff", fontWeight: 700 }}>
                  Total Contractors
                </Typography>

                <Typography size="18px" weight="medium" style={{ color: "#FFFFFFB2", fontWeight: 400 }}>
                  {landlordStatsData ? landlordStatsData.contractor : 0}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: 1 }}>
                <img src="/img/house.png" alt="" style={{ width: 60, height: 60 }} />
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: '#8093FE',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <div>
                <Typography size="22px" variant="h5" style={{ color: "#fff", fontWeight: 700 }}>
                  Total Internal Staff
                </Typography>

                <Typography size="18px" weight="medium" style={{ color: "#FFFFFFB2", fontWeight: 400 }}>
                  {landlordStatsData ? landlordStatsData.internalStaff : 0}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: 1 }}>
                <img src="/img/tenant.png" alt="" style={{ width: 60, height: 60 }} />
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>

          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>

            <div style={{ width: '100%', height: 500, marginTop: '10px', marginBottom: '20px', border: "20px", }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="70%"
                    innerRadius={0}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>

                </PieChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{ width: '100%', height: 500, marginTop: '10px', marginBottom: '20px', border: "20px", }}>
              <BarChart width={600} height={300} data={this.state.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </div>
          </Grid>

        </Grid> */}

        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: '#8093FE',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <div>
                <Typography variant="h5" style={{ color: "#fff", fontWeight: 700 }}>
                  Total Contractors
                </Typography>
                <Typography variant="body1" style={{ color: "#FFFFFFB2", fontWeight: 400 }}>
                  {landlordStatsData ? landlordStatsData.contractor : 0}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: 1 }}>
                <img src="/img/house.png" alt="" style={{ width: '60px', height: '60px' }} />
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: '#8093FE',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <div>
                <Typography variant="h5" style={{ color: "#fff", fontWeight: 700 }}>
                  Total Internal Staff
                </Typography>
                <Typography variant="body1" style={{ color: "#FFFFFFB2", fontWeight: 400 }}>
                  {landlordStatsData ? landlordStatsData.internalStaff : 0}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: 1 }}>
                <img src="/img/tenant.png" alt="" style={{ width: '60px', height: '60px' }} />
              </div>
            </Box>
          </Grid>
          {/* <Grid item lg={4} md={4} sm={6} xs={12}></Grid>
          <Grid item lg={4} md={8} sm={12} xs={12} style={{ border: '1px solid #8093FE', borderRadius: "14px", margin: "10px" }}>
            <Typography variant="h5" style={{ color: "#000", fontWeight: 700 }}>
              Total Hours - Contractors
            </Typography>

            <ResponsiveContainer>
              <PieChart width={500} height={300}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={0}
                  outerRadius={120}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item lg={4} md={8} sm={12} xs={12} style={{ border: '1px solid #8093FE', borderRadius: "14px", margin: "10px" }}>
            <Typography variant="h5" style={{ color: "#000", fontWeight: 700 }}>
              Trade Hours - Contractors
            </Typography>

            <BarChart width={500} height={300} data={this.state.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>

          </Grid> */}
        </Grid>
      </>
    );
  }
}
Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users,
  };
}
export default connect(mapStateToProps)(Dashboard);
