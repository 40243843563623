import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  // Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";
import pdfIcon from "../../../../images/pdf-icon.svg";
import downloadIcon from "../../../../images/download-icon.svg";
import crossIcon from "../../../../images/cross-icon.svg";
import { fileService } from "../../../../_services/file.service";
import { autocompleteClasses } from "@mui/material";

export default function ViewJobPdfDetails(props) {
  let { show, handleClosePdf, viewDocumentName, viewDocumentLink } = props;
  //console.log("jobCreateModal  ",jobCreateModal);
  // const fullWidth = "60vw";
  console.log('viewDocumentLink___________', viewDocumentLink);
  const onDownloadFile = (fileName) => {
    console.log('1111111111', fileName);
    fileService
      .downloadFile(fileName)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName.substr(13));
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  return (
    // <Dialog open={show} onClose={handleClosePdf} fullWidth={true}
    //   maxWidth="md" >
    //   <AppBar>
    //     <Toolbar>
    //       <IconButton
    //         edge="start"
    //         color="inherit"
    //         onClick={handleClosePdf}
    //         aria-label="close"
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //       <Typography variant="h6">More Details</Typography>
    //     </Toolbar>
    //   </AppBar>
    //   <div style={{ minHeight: "40vh", maxWidth: "80vh", width: "100%" }}>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignItems: "center",
    //         paddingTop: "2px",
    //         paddingBottom: "50px",
    //       }}
    //     >
    //       <Button
    //         color="inherit"
    //         style={{ backgroundColor: "transparent", boxShadow: "none" }}
    //         onClick={handleClosePdf}
    //       >
    //         <img src={crossIcon} />
    //       </Button>
    //       <h4 style={{ fontSize: "26px", fontWeight: "500" }}>Document List</h4>
    //     </div>
    //     <div style={{ width: "100%" }} >
    //       {
    //         viewDocumentName && viewDocumentName.length > 0 ?
    //           viewDocumentName.map((element, index) => (

    //             <div
    //               style={{
    //                 display: "flex",

    //                 justifyContent: "start",
    //                 backgroundColor: "#f6f7f8",
    //                 padding: "12px",
    //                 marginBottom: "10px",
    //                 marginLeft: "20px",

    //                 width: "920px",


    //               }}
    //             >
    //               <span
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //               >
    //                 <img src={pdfIcon} alt=" " />
    //                 <p
    //                   style={{
    //                     fontSize: "18px",
    //                     marginLeft: "15px",
    //                     fontWeight: "500",
    //                   }}
    //                 >
    //                   {viewDocumentName[index]}
    //                 </p>
    //               </span>

    //               <Button
    //                 onClick={() => onDownloadFile(element)}
    //                 style={{ backgroundColor: "transparent", boxShadow: "none" }}
    //               >
    //                 <img src={downloadIcon} alt=" " />
    //               </Button>


    //             </div>


    //           ))

    //           : <div
    //             style={{
    //               display: "flex",
    //               flexDirection: "row",
    //               justifyContent: "space-around",
    //               background: "#f6f7f8",
    //               paddingTop: "2px",
    //               marginBottom: "2px",

    //             }}
    //           >
    //             <span
    //               style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //               }}
    //             >

    //               <p
    //                 style={{
    //                   fontSize: "18px",
    //                   marginLeft: "15px",
    //                   fontWeight: "500",
    //                 }}
    //               >
    //                 Not Found
    //               </p>
    //             </span>
    //             <Button
    //               style={{ backgroundColor: "transparent", boxShadow: "none" }}
    //             >

    //             </Button>
    //           </div>
    //       }


    //     </div>
    //   </div>
    // </Dialog>


    <Dialog open={show} onClose={handleClosePdf} fullWidth maxWidth="xs">
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={handleClosePdf} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <div>
        <div style={{ width: "100%", marginTop: "10px", marginBottom: "20px", height: "300px", overflowY: "auto" }}>
          {viewDocumentName && viewDocumentName.length > 0 ? viewDocumentName.map((element, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#f6f7f8", padding: "12px", marginBottom: "10px", margin: "auto", width: "380px", borderBottom: "1px solid black" }}>
              <img src={pdfIcon} alt=" " />
              <p style={{ fontSize: "12px", marginLeft: "15px", fontWeight: "500", wordBreak: "break-all" }}>{element}</p>
              <Button onClick={() => onDownloadFile(element)} style={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <img src={downloadIcon} alt=" " />
              </Button>
            </div>
          )) :
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", background: "#f6f7f8", paddingTop: "2px", marginBottom: "2px" }}>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "12px", marginLeft: "15px", fontWeight: "500", }}>Not Found</p>
              </span>
              <Button style={{ backgroundColor: "transparent", boxShadow: "none" }} />
            </div>}
        </div>
      </div>
    </Dialog>
  );
}
