import React from "react";
import { Dialog, Container, CssBaseline, Grid, Typography, } from "@material-ui/core";
import Select2 from 'react-select';
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function AddWorkerDialog(props) {

  let {
    classes,
    workerAddCloseModal,
    selectedAssignedData,
    itemsJob,
    assignWorkerForJobSubmit,
    handleAssignWorkerDropDown,
    addWorkerModal } = props;


  let colourOptions = []
  if (itemsJob && itemsJob.length > 0) {
    itemsJob.forEach((emp) => {

      let result = selectedAssignedData && selectedAssignedData.assigned_internal_staff_status_m ? selectedAssignedData.assigned_internal_staff_status_m.filter(o => (o.id == emp && emp.staffId && emp.staffId.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        colourOptions.push(
          {
            value: emp.first_name + " " + emp.last_name,
            label: emp.first_name + " " + emp.last_name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        colourOptions.push(
          {
            value: emp.first_name + " " + emp.last_name,
            label: emp.first_name + " " + emp.last_name,
            id: emp.id,
            disabled: false
          }
        )
      }


    })
  }



  let freezOptions = []
  if (itemsJob && itemsJob.length > 0) {
    itemsJob.forEach((emp) => {
      freezOptions.push(
        {
          value: emp.first_name + " " + emp.last_name,
          label: emp.first_name + " " + emp.last_name,
          id: emp.id,
        }
      )
      const autoselect = itemsJob.slice(0, 2);
      console.log("autoselectautoselectautoselect", autoselect);
    })
  }

  return (
    // <Dialog fullScreen open={addWorkerModal} onClose={workerAddCloseModal}>

    //   <AppBar className={classes.appBar}>
    //     <Toolbar>
    //       <IconButton
    //         edge="start"
    //         color="inherit"
    //         onClick={workerAddCloseModal}
    //         aria-label="close"
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //       <Typography variant="h6" className={classes.title}>
    //         Add Internal Staff
    //       </Typography>
    //       <Button color="inherit" onClick={workerAddCloseModal}>
    //         Cancel
    //       </Button>
    //     </Toolbar>
    //   </AppBar>
    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    //     <div className={classes.paper}>
    //       <Typography component="h1" variant="h5">
    //         Add Internal Staff
    //       </Typography>

    //       <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
    //         <Grid container spacing={2} style={{ width: '500px' }}>

    //           <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>

    //             <Select2
    //               width="full"
    //               isMulti
    //               onChange={handleAssignWorkerDropDown}
    //               defaultValue={selectedAssignedData && selectedAssignedData.assigned_internal_staff_status_m ?
    //                 selectedAssignedData.assigned_internal_staff_status_m.map((element) => ({
    //                   value: element && element.staffId && element.staffId.first_name ? element.staffId.first_name : null + " " + element && element.staffId && element.staffId.last_name ? element.staffId.last_name : null,
    //                   label: element && element.staffId && element.staffId.first_name ? element.staffId.first_name : null + " " + element && element.staffId && element.staffId.last_name ? element.staffId.last_name : null,
    //                   id: element.staffId.id,
    //                 })) : []}
    //               options={colourOptions}
    //               isOptionDisabled={(option) => option.disabled}
    //               className="basic-multi-select width: 'full'"
    //               classNamePrefix="Activities"
    //             />

    //           </Grid>



    //           <div style={{ margin: "7px" }}></div>

    //           <Grid item xs={12}></Grid>
    //         </Grid>
    //         <Button
    //           fullWidth
    //           variant="contained"
    //           color="primary"
    //           type="submit"
    //           className={classes.submit}
    //         >
    //           Save
    //         </Button>
    //       </ValidatorForm>

    //     </div>
    //   </Container>
    // </Dialog>


    <Dialog open={addWorkerModal} onClose={workerAddCloseModal}>
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={workerAddCloseModal} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div>
          <Typography component="h1" variant="h5">Add Internal Staff</Typography>
          <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Select2 style={{ zIndex: "100px" }}
                  width="full"
                  isMulti
                  onChange={handleAssignWorkerDropDown}
                  defaultValue={selectedAssignedData?.assigned_internal_staff_status_m?.map((element) => ({
                    value: `${element?.staffId?.first_name || ''} ${element?.staffId?.last_name || ''}`,
                    label: `${element?.staffId?.first_name || ''} ${element?.staffId?.last_name || ''}`,
                    id: element.staffId.id,
                  })) || []}
                  options={colourOptions}
                  isOptionDisabled={(option) => option.disabled}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center', marginBottom: "2rem", marginTop: "2rem" }}>
                <Button fullWidth variant="contained" color="primary" type="submit" className={classes.submit}>Save</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>



  );
}
