import React, { Component } from "react";
import { Grid, Typography, TextField, } from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewContractorLandlordDialog from "./components/ViewContractorLandlordDialog/ViewContractorLandlordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { contractorLandlordActions } from "../../_actions";
import {
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

class ContractorLandlordManagement extends Component {
  constructor(props) {
    document.title = "ATS | LandLord";
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleOpenViewCreateModal = this.handleOpenViewCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      ViewContractorLandlordCreateModal: false,
      keyWord: "",
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 1,
      size: this.state.size,
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));
  }

  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      size: this.state.size,
      roll_id: 1,
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
      roll_id: 1,
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));
  }

  handleOpenViewCreateModal(data) {
    this.setState({
      ViewContractorLandlordCreateModal: true,
      viewRowData: data,
    });
  }

  handleClose() {
    this.setState({ ViewContractorLandlordCreateModal: false });
  }
  render() {
    let { contractorsLandlord, classes, internalstaff } = this.props;
    let { itemsJob, total } = contractorsLandlord;
    return (
      <>
        <PageTitle title="Contractors" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search Company Name"
                    type="search"
                    placeholder="Search Company"
                    name="keyWord"
                    onChange={this.handleSearch}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              {itemsJob && itemsJob.length > 0 ? (
                <>
                  <Table
                    data={itemsJob}
                    offset={this.state.offset}
                    handleOpenViewCreateModal={this.handleOpenViewCreateModal}
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
            </Widget>
          </Grid>
        </Grid>
        <ViewContractorLandlordDialog
          jobCreateModal={this.state.ViewContractorLandlordCreateModal}
          classes={classes}
          viewRowData={this.state.viewRowData}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { contractorsLandlord, } = state;
  return {
    contractorsLandlord,
    // contractorsLandlord
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ContractorLandlordManagement)),
);
