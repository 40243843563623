import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from 'moment';

import { Button } from "../../../../components/Wrappers/Wrappers";
import Pdf from "react-to-pdf";

import { PDFExport } from '@progress/kendo-react-pdf';
import PageTemplate from './pageTemplate';
const ref = React.createRef();

export default function ViewJobDialog(props) {

  const pdfExportComponent = React.useRef(null);

  let tz = new Date().getTimezoneOffset();

  let {
    classes,
    // formData,
    jobCreateModal,
    handleClose,
    onOffTime,
    // handleViewClose,
    viewRowData,
    imageUrl,
    // handleChangeInput,
    // handleSubmit,
    // data
  } = props;

  // const mapData = viewRowData && viewRowData.assigned_internal_staff_status_m

  // console.log("mapData_____________________", mapData);

  // let responseData = mapData.map((ele) => {
  //   ele
  // })
  // console.log("mapData_____________________", responseData);

  // console.log("11111111111111111111111111111", data);
  console.log("viewRowData___________________________", viewRowData);
  console.log("imageUrl______________", imageUrl);
  // let id;
  // if (viewRowData) {
  //   id = viewRowData.id;
  // }

  // useEffect(() => {
  //   if (id) {
  //     // console.log("ididid", id);
  //     dispatch(archiveActions.getJobDetails(id));
  //   }
  // }, [id]);

  const getLocation = () => {
    if (viewRowData.location_id) {
      const { location_id } = viewRowData;
      const { line1, line2, line3, city, state, pin } = location_id;
      return `${line1}-${line2}-${line3}-${city}-${state}-${pin}`;
    }
    return "NA";
  };

  const getCompanyName = () => {
    if (viewRowData.assinged_IS) {
      const { assinged_IS } = viewRowData;
      const { company_name } = assinged_IS;
      return company_name;
    }
    if (viewRowData.assigned_worker && viewRowData.assigned_worker.length > 0) {
      const { assigned_worker } = viewRowData;
      const { company_name } = assigned_worker[0];
      return company_name;
    }
    return "NA";
  };

  const getDate = () => {
    const { createdAt } = viewRowData;
    return new Date(createdAt).toLocaleString();
  };
  const getFormatedDate = (date) => {
    var dateString = new Date(date).toISOString().split("T")[0];
    return dateString;
  };

  // const getImage = () => {
  //   let image = {};
  //   if (viewRowData.imageLinks) {
  //     const { imageLinks } = viewRowData;

  //     imageLinks.map((element,index) => {
  //       image[index] = element;
  //     });
  //     return image;
  //   }
  //   return "NA";
  // };
  const getImage = () => {
    let image = [];
    if (viewRowData.imageLinks) {
      const { imageLinks } = viewRowData;

      Object.keys(imageLinks).map((index) => {
        image[index] = imageLinks[index];
      });
      return image;
    }
    return "NA";
  };



  // {this.state.recentScoreInfo && this.state.recentScoreInfo.scorecard && Object.keys(this.state.recentScoreInfo.scorecard).length > 0 ?
  //   Object.keys(this.state.recentScoreInfo.scorecard).map((index) => (<>



  const getAssignedWorker = () => {
    let workerNames = "";
    if (viewRowData.assigned_worker) {
      const { assigned_worker } = viewRowData;
      assigned_worker.map((worker) => {
        const { contact_name } = worker;
        workerNames = workerNames + contact_name + " ";
      });
      return workerNames;
    }
    return "NA";
  };
  const getAddress = () => {
    return ` ${viewRowData.location_id && viewRowData.location_id.line1
      ? viewRowData.location_id.line1
      : "-"
      }
    ${viewRowData.location_id && viewRowData.location_id.line2
        ? viewRowData.location_id.line2
        : "-"
      }
    ${viewRowData.location_id && viewRowData.location_id.line3
        ? viewRowData.location_id.line3
        : "-"
      }`;
  };
  const generateAddress = () => {
    let address = "";
    if (viewRowData.location_id) {
      const { line1, line2, line3, city, state, pin } = viewRowData.location_id;
      address = `${line1} ${line2} ${line3} ${city} ${state} ${pin}`;
    }
    return address;
  };
  // const getContractorName = () => {
  //   if (viewRowData.assign_to) {
  //     const { company_name } = viewRowData.assign_to;
  //     return company_name;
  //   }
  // };
  const getFormattedDate = (dateInMilliseconds) => {
    if (!dateInMilliseconds) {
      return " - ";
    }
    const date = new Date(dateInMilliseconds);
    return date.toDateString() + " " + date.toLocaleTimeString();
  };
  const getCompletionDate = () => {
    return getFormattedDate(viewRowData.completion_date);
  };
  const getJobStartDate = () => {
    return getFormattedDate(viewRowData.start_date);
  };

  return (
    <>
      {/* <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
        {console.log("ddata", viewRowData)}
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <CloseIcon onClick={handleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
        </div>

        {viewRowData.status === 2 && (
          <Grid container>
            <Grid item sm />
            <Grid item></Grid>
            <Grid item style={{ marginRight: "3.15%", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={`${classes.submit}`}
                onClick={() => {
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
              >
                Download Report
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        )}
        <section style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginBottom: "20px" }}>
            <div className={classes.jobPaper} style={{ marginBottom: "20px" }}>
              <Typography component="h1" variant="h5">
                <u>Job Details</u>
              </Typography>
            </div>
            <div className={classes.jobPaper}>
              <Typography style={{ fontSize: "17px" }} component="h1" variant="h5">
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Id : </b>
                  {viewRowData.job_id}
                </Box>
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Title : </b>
                  {viewRowData.job_title}
                </Box>
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Details :</b> {viewRowData.job_details}
                </Box>
                <br />
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Address :</b>
                </Box>
                <Box
                  style={{
                    marginLeft: "40px",
                    marginTop: "5px",
                    fontSize: "16px",
                  }}
                >
                  <Box className={classes.jobText}>
                    <b>Street Number/Name : </b>
                    {getAddress()}
                  </Box>
                  <Box className={classes.jobText}>
                    <b> Postal/Zip Code :</b>
                    {viewRowData.location_id && viewRowData.location_id.pin
                      ? viewRowData.location_id.pin
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>City : </b>
                    {viewRowData.location_id && viewRowData.location_id.city
                      ? viewRowData.location_id.city
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Province/State : </b>
                    {viewRowData.location_id && viewRowData.location_id.state
                      ? viewRowData.location_id.state
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Country : </b>
                    {viewRowData.location_id && viewRowData.location_id.country
                      ? viewRowData.location_id.country
                      : "-"}
                  </Box>
                </Box>
                <br />
                <br />
                {viewRowData.assign_to && (
                  <>
                    <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                      <b>Contractor :</b>
                    </Box>
                    <Box style={{ marginLeft: "40px", fontSize: "16px" }}>
                      <Box className={classes.jobText}>
                        <b>Company Name : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.company_name
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Name : </b>
                        {viewRowData && viewRowData.assign_to && viewRowData.assign_to.contact_name
                          ? viewRowData.assign_to.contact_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Email : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.email
                          ? viewRowData.assign_to.email
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Number : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.contact
                          ? viewRowData.assign_to.contact
                          : "-"}
                      </Box>
                    </Box>{" "}
                    <br />
                  </>
                )}

                {viewRowData &&
                  viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.map((worker, index) => {
                    return (
                      <>
                        <br />
                        <Box
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <b>Worker {index + 1}:</b>
                        </Box>{" "}
                        <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                          <Box className={classes.jobText}>
                            <b>Company Name : </b>{" "}
                            {viewRowData && viewRowData.assign_to && viewRowData.assign_to.company_name
                              ? viewRowData.assign_to.company_name
                              : "-"}
                          </Box>
                          <Box className={classes.jobText}>
                            <b>Worker Name : </b>{" "}
                            {worker && worker.workerId
                              ? worker.workerId.first_name + " " + worker.workerId.last_name
                              : "-"}
                          </Box>
                          <Box className={classes.jobText}>
                            <b>Contact No. : </b>{" "}
                            {worker && worker.workerId && worker.workerId.contact ? worker.workerId.contact : "-"}
                          </Box>
                        </Box>
                      </>
                    );
                  })}

                <br />
                {
                  viewRowData && viewRowData.jobType_Id && viewRowData.jobType_Id.name == "PM" ? <>
                    <Box
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: viewRowData.jobType_Id ? (viewRowData.jobType_Id.name == "PM" ? "block" : "none") : "none",
                      }}
                    >
                      <b>PM Dates: </b>
                    </Box>
                    {viewRowData.jobType_PM && viewRowData.jobType_PM.map((date, i) => {
                      return (<React.Fragment >
                        <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                          <Box className={classes.jobText}>
                            <b>Date {i + 1}:</b>{" "}
                            {date && date ? getFormatedDate(date) : "-"}
                          </Box>
                        </Box>
                      </React.Fragment>
                      );
                    })}</> : null
                }

                {viewRowData.assinged_IS_m && viewRowData.assinged_IS_m.map((ele, index) => {
                  return <React.Fragment>
                    <Box style={{ textOverflow: "ellipsis" }} key={index}>
                      <b>Internal Staff {index + 1}:</b>
                      <Box style={{ marginLeft: "20px", fontSize: "16px" }} >
                        <Box className="pl-3 fw-normal">
                          <b> Contact Name : </b>
                          {ele && ele.first_name ? ele.first_name + " " + ele.last_name : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact Email : </b>
                          {ele && ele.email ? ele.email : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact No. : </b>
                          {ele && ele.contact ? ele.contact : "-"}
                        </Box>
                        <br />
                      </Box>
                    </Box>
                  </React.Fragment>
                })}
              </Typography>
            </div>

          </div>
          {viewRowData.status === 2 && (

            <PDFExport
              keepTogether="p" paperSize="A4"
              scale={0.75}
              ref={pdfExportComponent}
            >


              <div className={classes.serviceReportContainer} style={{ padding: "25px", justifyContent: "center" }}>
                <div
                  style={{
                    padding: "20px",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "30px", border: "5px solid #2E74B5" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "baseline",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src="./Logo V2.jpg"
                        alt="logo"
                        className={classes.reportLogo}
                      />
                      <h2
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "150px",
                        }}
                      >
                        <u>SERVICE REPORT</u>
                      </h2>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className={classes.itemsContainer}>
                        <span>CONTACT NAME :</span>
                        <span className={classes.itemDetails}>
                          {viewRowData.created_by.contact_name}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>JOB TITLE :</span>

                        <span className={classes.itemDetails}>
                          {viewRowData.job_title
                            ? viewRowData.job_title
                            : viewRowData.job_title}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>JOB DETAILS :</span>

                        <span className={classes.itemDetails}>
                          {viewRowData.job_details
                            ? viewRowData.job_details
                            : viewRowData.job_details}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>JOB ID :</span>
                        <span className={classes.itemDetails}>
                          {viewRowData.job_id ? viewRowData.job_id : "-"}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>LOCATION :</span>
                        <span className={classes.itemDetails}>
                          {generateAddress()}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>DATE CREATED :</span>
                        <span className={classes.itemDetails}>
                          {moment(new Date(parseInt(viewRowData.createdAt))).utc(tz).format("MMMM Do YYYY ")}
                        </span>
                      </div>

                      <div className={classes.itemsContainer}>
                        <span>CONTRACTOR :</span>
                        <span className={classes.itemDetails}>
                          {viewRowData && viewRowData.assign_to
                            ? viewRowData.assign_to.company_name
                            : "-"}
                        </span>
                      </div>
                      <div className={classes.itemsContainer}>
                        <span>INTERNAL STAFF :</span>
                        <span className={classes.itemDetails}>
                          {viewRowData && viewRowData.assinged_IS
                            ? viewRowData.assinged_IS.email
                            : "-"}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <div className={(classes.itemsContainer, classes.itemsContainerDate)}
                        >
                          <span>JOB START DATE :</span>
                          <span className={classes.itemDetails}>
                            {getJobStartDate()}
                          </span>
                        </div>
                        <div
                          className={
                            (classes.itemsContainer, classes.itemsContainerDate)
                          }
                        >
                          <span>DATE OF COMPLETION :</span>
                          <span className={classes.itemDetails}>
                            {getCompletionDate()}
                          </span>
                        </div>
                      </div>
                      {viewRowData && viewRowData.assigned_internal_staff_status_m && viewRowData.assigned_internal_staff_status_m.length > 0 ? viewRowData.assigned_internal_staff_status_m.map((element, index) => <React.Fragment>
                        {element.staffStatus == 2 ? <>
                          <div className={classes.itemLayoutOuter}>

                            <div className={classes.itemsContainer}>
                              <span>EMPLOYEE :</span>
                              <span className={classes.itemDetails}>
                                {element.staffId.first_name + " " + element.staffId.last_name}

                              </span>
                            </div>
                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>
                            <div className={classes.itemLayout}>


                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >
                                  <span>TOTAL ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOnTime : "-"} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>TOTAL OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOffTime : "-"} </span>
                                </div>
                              </div>
                            </div>

                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK START TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK END TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>DESCRIPTION OF WORK : </span>
                              <span className={classes.itemDetails}>
                                {element && element.staffWorkDetails ? element.staffWorkDetails : "-"}
                              </span>
                            </div>
                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>
                                <u>ATTACHED MEDIA :</u>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                  {
                                    element && element.image && element.image.length > 0 ?
                                      element.image.map((elmentdd) => (<>
                                        <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                      </>
                                      ))
                                      : null
                                  }
                                </div>
                              </span>
                            </div>
                          </div>
                          <br />
                        </> : null} </React.Fragment>) : viewRowData && viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.length > 0 ? viewRowData.assigned_worker_status.map((element) => <React.Fragment>
                          {element.workerStatus == 2 ? <>
                            <div className={classes.itemLayoutOuter}>

                              <div className={classes.itemsContainer}>
                                <span>EMPLOYEE :</span>
                                <span className={classes.itemDetails}>
                                  {element.workerId.first_name + " " + element.workerId.last_name}

                                </span>
                              </div>
                              <div className={classes.itemLayout}>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >

                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >

                                    <span>ON-SITE TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                      element.timeData.map((elmentdd) => (<>
                                        {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                        <br />
                                      </>
                                      ))
                                      : null} </span>
                                  </div>
                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >

                                    <span>OFF-SITE TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                      element.timeData.map((elmentdd) => (<>
                                        {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}

                                        <br />
                                      </>
                                      ))
                                      : null} </span>
                                  </div>
                                </div>
                              </div>
                              <div className={classes.itemLayout}>


                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >
                                    <span>TOTAL ON-SITE TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                      element.onOffSite.finalOnTime : "-"} </span>
                                  </div>
                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >

                                    <span>TOTAL OFF-SITE TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                      element.onOffSite.finalOffTime : "-"} </span>
                                  </div>
                                </div>
                              </div>

                              <div className={classes.itemLayout}>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >

                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >

                                    <span>OFF-NETWORK START TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                      element.delayDuration.map((elmentdd) => (<>
                                        {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                        <br />
                                      </>
                                      ))
                                      : null} </span>
                                  </div>
                                  <div
                                    className={
                                      (classes.itemsContainer, classes.itemsContainerDate)
                                    }
                                  >

                                    <span>OFF-NETWORK END TIME :</span>
                                    <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                      element.delayDuration.map((elmentdd) => (<>
                                        {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                        <br />
                                      </>
                                      ))
                                      : null} </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={classes.itemsContainer}
                                style={{ marginTop: "30px" }}
                              >
                                <span>DESCRIPTION OF WORK : </span>
                                <span className={classes.itemDetails}>
                                  {element && element.workerWorkDetails ? element.workerWorkDetails : "-"}
                                </span>
                              </div>
                              <div
                                className={classes.itemsContainer}
                                style={{ marginTop: "30px" }}
                              >
                                <span>
                                  <u>ATTACHED MEDIA :</u>
                                  <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                    {
                                      element && element.image && element.image.length > 0 ?
                                        element.image.map((elmentdd) => (<>
                                          <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                        </>
                                        ))
                                        : null
                                    }
                                  </div>
                                </span>
                              </div>



                            </div>
                            <br />
                          </> : null}
                        </React.Fragment>) : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
          )}
        </section>
      </Dialog> */}


      <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <CloseIcon onClick={handleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
        </div>

        {viewRowData.status === 2 && (
          <Grid container>
            <Grid item sm />
            <Grid item></Grid>
            <Grid item style={{ marginRight: "3.15%", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={`${classes.submit}`}
                onClick={() => {
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
              >
                Download Report
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        )}

        <section style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginBottom: "20px" }}>
            <div className={classes.jobPaper} style={{ marginBottom: "20px" }}>
              <Typography component="h1" variant="h5">
                <u>Job Details</u>
              </Typography>
            </div>
            <div className={classes.jobPaper}>
              <Typography style={{ fontSize: "17px" }} component="h1" variant="h5">
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Id : </b>
                  {viewRowData.job_id}
                </Box>
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Title : </b>
                  {viewRowData.job_title}
                </Box>
                <Box
                  className={classes.jobText}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  <b>Job Details :</b> {viewRowData.job_details}
                </Box>
                <br />
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  <b>Address :</b>
                </Box>
                <Box
                  style={{
                    marginLeft: "40px",
                    marginTop: "5px",
                    fontSize: "16px",
                  }}
                >
                  <Box className={classes.jobText}>
                    <b>Street Number/Name : </b>
                    {getAddress()}
                  </Box>
                  <Box className={classes.jobText}>
                    <b> Postal/Zip Code :</b>
                    {viewRowData.location_id && viewRowData.location_id.pin
                      ? viewRowData.location_id.pin
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>City : </b>
                    {viewRowData.location_id && viewRowData.location_id.city
                      ? viewRowData.location_id.city
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Province/State : </b>
                    {viewRowData.location_id && viewRowData.location_id.state
                      ? viewRowData.location_id.state
                      : "-"}
                  </Box>
                  <Box className={classes.jobText}>
                    <b>Country : </b>
                    {viewRowData.location_id && viewRowData.location_id.country
                      ? viewRowData.location_id.country
                      : "-"}
                  </Box>
                </Box>
                <br />
                <br />
                {viewRowData.assign_to && (
                  <>
                    <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                      <b>Contractor :</b>
                    </Box>
                    <Box style={{ marginLeft: "40px", fontSize: "16px" }}>
                      <Box className={classes.jobText}>
                        <b>Company Name : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.company_name
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Name : </b>
                        {viewRowData && viewRowData.assign_to && viewRowData.assign_to.contact_name
                          ? viewRowData.assign_to.contact_name
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Email : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.email
                          ? viewRowData.assign_to.email
                          : "-"}
                      </Box>
                      <Box className={classes.jobText}>
                        <b>Contact Number : </b>
                        {viewRowData.assign_to && viewRowData.assign_to.contact
                          ? viewRowData.assign_to.contact
                          : "-"}
                      </Box>
                    </Box>{" "}
                    <br />
                  </>
                )}

                {viewRowData &&
                  viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.map((worker, index) => {
                    return (
                      <>
                        <br />
                        <Box
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <b>Worker {index + 1}:</b>
                        </Box>{" "}
                        <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                          <Box className={classes.jobText}>
                            <b>Company Name : </b>{" "}
                            {viewRowData && viewRowData.assign_to && viewRowData.assign_to.company_name
                              ? viewRowData.assign_to.company_name
                              : "-"}
                          </Box>
                          <Box className={classes.jobText}>
                            <b>Worker Name : </b>{" "}
                            {worker && worker.workerId
                              ? worker.workerId.first_name + " " + worker.workerId.last_name
                              : "-"}
                          </Box>
                          <Box className={classes.jobText}>
                            <b>Contact No. : </b>{" "}
                            {worker && worker.workerId && worker.workerId.contact ? worker.workerId.contact : "-"}
                          </Box>
                        </Box>
                      </>
                    );
                  })}

                <br />
                {
                  viewRowData && viewRowData.jobType_Id && viewRowData.jobType_Id.name == "PM" ? <>
                    <Box
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: viewRowData.jobType_Id ? (viewRowData.jobType_Id.name == "PM" ? "block" : "none") : "none",
                      }}
                    >
                      <b>PM Dates: </b>
                    </Box>
                    {viewRowData.jobType_PM && viewRowData.jobType_PM.map((date, i) => {
                      return (<React.Fragment >
                        <Box style={{ marginLeft: "20px", fontSize: "16px" }}>
                          <Box className={classes.jobText}>
                            <b>Date {i + 1}:</b>{" "}
                            {date && date ? getFormatedDate(date) : "-"}
                          </Box>
                        </Box>
                      </React.Fragment>
                      );
                    })}</> : null
                }

                {viewRowData.assinged_IS_m && viewRowData.assinged_IS_m.map((ele, index) => {
                  return <React.Fragment>
                    <Box style={{ textOverflow: "ellipsis" }} key={index}>
                      <b>Internal Staff {index + 1}:</b>
                      <Box style={{ marginLeft: "20px", fontSize: "16px" }} >
                        <Box className="pl-3 fw-normal">
                          <b> Contact Name : </b>
                          {ele && ele.first_name ? ele.first_name + " " + ele.last_name : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact Email : </b>
                          {ele && ele.email ? ele.email : "-"}
                        </Box>
                        <Box className="pl-3 fw-normal">
                          <b> Contact No. : </b>
                          {ele && ele.contact ? ele.contact : "-"}
                        </Box>
                        <br />
                      </Box>
                    </Box>
                  </React.Fragment>
                })}
              </Typography>
            </div>
          </div>
          {viewRowData.status === 2 && (
            <PDFExport
              keepTogether="p" paperSize="A4"
              scale={0.75}
              ref={pdfExportComponent}
            >
              <div className={classes.serviceReportContainer} style={{ padding: "25px", justifyContent: "center" }}>
                <div style={{ padding: "30px", border: "5px solid #2E74B5" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "baseline",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src="./Logo V2.jpg"
                      alt="logo"
                      className={classes.reportLogo}
                    />
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "150px",
                      }}
                    >
                      <u>SERVICE REPORT</u>
                    </h2>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={classes.itemsContainer}>
                      <span>CONTACT NAME :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData.created_by.contact_name}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB TITLE :</span>

                      <span className={classes.itemDetails}>
                        {viewRowData.job_title
                          ? viewRowData.job_title
                          : viewRowData.job_title}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB DETAILS :</span>

                      <span className={classes.itemDetails}>
                        {viewRowData.job_details
                          ? viewRowData.job_details
                          : viewRowData.job_details}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>JOB ID :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData.job_id ? viewRowData.job_id : "-"}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>LOCATION :</span>
                      <span className={classes.itemDetails}>
                        {generateAddress()}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>DATE CREATED :</span>
                      <span className={classes.itemDetails}>
                        {moment(new Date(parseInt(viewRowData.createdAt))).utc(tz).format("MMMM Do YYYY ")}
                      </span>
                    </div>

                    <div className={classes.itemsContainer}>
                      <span>CONTRACTOR :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData && viewRowData.assign_to
                          ? viewRowData.assign_to.company_name
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.itemsContainer}>
                      <span>INTERNAL STAFF :</span>
                      <span className={classes.itemDetails}>
                        {viewRowData && viewRowData.assinged_IS
                          ? viewRowData.assinged_IS.email
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className={(classes.itemsContainer, classes.itemsContainerDate)}
                      >
                        <span>JOB START DATE :</span>
                        <span className={classes.itemDetails}>
                          {getJobStartDate()}
                        </span>
                      </div>
                      <div
                        className={
                          (classes.itemsContainer, classes.itemsContainerDate)
                        }
                      >
                        <span>DATE OF COMPLETION :</span>
                        <span className={classes.itemDetails}>
                          {getCompletionDate()}
                        </span>
                      </div>
                    </div>
                    {viewRowData && viewRowData.assigned_internal_staff_status_m && viewRowData.assigned_internal_staff_status_m.length > 0 ? viewRowData.assigned_internal_staff_status_m.map((element, index) => <React.Fragment>
                      {element.staffStatus == 2 ? <>
                        <div className={classes.itemLayoutOuter}>

                          <div className={classes.itemsContainer}>
                            <span>EMPLOYEE :</span>
                            <span className={classes.itemDetails}>
                              {element.staffId.first_name + " " + element.staffId.last_name}

                            </span>
                          </div>
                          <div className={classes.itemLayout}>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >

                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>ON-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                  element.timeData.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                  element.timeData.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                            </div>
                          </div>
                          <div className={classes.itemLayout}>


                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >
                                <span>TOTAL ON-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                  element.onOffSite.finalOnTime : "-"} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>TOTAL OFF-SITE TIME :</span>
                                <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                  element.onOffSite.finalOffTime : "-"} </span>
                              </div>
                            </div>
                          </div>

                          <div className={classes.itemLayout}>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >

                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-NETWORK START TIME :</span>
                                <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                  element.delayDuration.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                              <div
                                className={
                                  (classes.itemsContainer, classes.itemsContainerDate)
                                }
                              >

                                <span>OFF-NETWORK END TIME :</span>
                                <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                  element.delayDuration.map((elmentdd) => (<>
                                    {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                    <br />
                                  </>
                                  ))
                                  : null} </span>
                              </div>
                            </div>
                          </div>

                          <div
                            className={classes.itemsContainer}
                            style={{ marginTop: "30px" }}
                          >
                            <span>DESCRIPTION OF WORK : </span>
                            <span className={classes.itemDetails}>
                              {element && element.staffWorkDetails ? element.staffWorkDetails : "-"}
                            </span>
                          </div>
                          <div
                            className={classes.itemsContainer}
                            style={{ marginTop: "30px" }}
                          >
                            <span>
                              <u>ATTACHED MEDIA :</u>
                              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                {
                                  element && element.image && element.image.length > 0 ?
                                    element.image.map((elmentdd) => (<>
                                      <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                    </>
                                    ))
                                    : null
                                }
                              </div>
                            </span>
                          </div>
                        </div>
                        <br />
                      </> : null} </React.Fragment>) : viewRowData && viewRowData.assigned_worker_status && viewRowData.assigned_worker_status.length > 0 ? viewRowData.assigned_worker_status.map((element) => <React.Fragment>
                        {element.workerStatus == 2 ? <>
                          <div className={classes.itemLayoutOuter}>

                            <div className={classes.itemsContainer}>
                              <span>EMPLOYEE :</span>
                              <span className={classes.itemDetails}>
                                {element.workerId.first_name + " " + element.workerId.last_name}

                              </span>
                            </div>
                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.onSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.timeData && element.timeData.length > 0 ?
                                    element.timeData.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.offSiteTime))).utc(tz).format('MMMM Do YYYY, h:mm:ss a ')}

                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>
                            <div className={classes.itemLayout}>


                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >
                                  <span>TOTAL ON-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOnTime : "-"} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>TOTAL OFF-SITE TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.onOffSite && element.onOffSite.finalOnTime ?
                                    element.onOffSite.finalOffTime : "-"} </span>
                                </div>
                              </div>
                            </div>

                            <div className={classes.itemLayout}>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >

                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK START TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.from) * 1000)).utc(tz).format("MMMM Do YYYY, h:mm:ss a ")}

                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                                <div
                                  className={
                                    (classes.itemsContainer, classes.itemsContainerDate)
                                  }
                                >

                                  <span>OFF-NETWORK END TIME :</span>
                                  <span className={classes.itemDetails}>{element && element.delayDuration && element.delayDuration.length > 0 ?
                                    element.delayDuration.map((elmentdd) => (<>
                                      {moment(new Date(parseInt(elmentdd.to) * 1000)).utc(tz).format('MMMM Do YYYY, h:mm:ss a')}
                                      <br />
                                    </>
                                    ))
                                    : null} </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>DESCRIPTION OF WORK : </span>
                              <span className={classes.itemDetails}>
                                {element && element.workerWorkDetails ? element.workerWorkDetails : "-"}
                              </span>
                            </div>
                            <div
                              className={classes.itemsContainer}
                              style={{ marginTop: "30px" }}
                            >
                              <span>
                                <u>ATTACHED MEDIA :</u>
                                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '1rem', marginTop: '1rem', width: '100%' }} >
                                  {
                                    element && element.image && element.image.length > 0 ?
                                      element.image.map((elmentdd) => (<>
                                        <img className="mt-2 p-2" src={imageUrl + elmentdd} height="100" width="100" alt="" />
                                      </>
                                      ))
                                      : null
                                  }
                                </div>
                              </span>
                            </div>



                          </div>
                          <br />
                        </> : null}
                      </React.Fragment>) : null
                    }
                  </div>
                </div>
              </div>
            </PDFExport>
          )}
        </section>

        <style jsx>{`
    @media only screen and (max-width: 600px) {
      /* Add styles for screens up to 600px */
      /* Example: */
      .jobText {
        font-size: 14px;
      }
    }

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
      /* Add styles for screens between 601px and 1024px */
      /* Example: */
      .jobText {
        font-size: 16px;
      }
    }

    /* Add more media queries as needed */
  `}</style>
      </Dialog>

    </>
  );
}
