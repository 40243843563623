import {
  workerConstants
} from '../_constants';

export function workers(state = {}, action) {

  switch (action.type) {


    case workerConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.GETALL_SUCCESS:
      return {
        ...state,
        addWorkerSuccess: false,
        items: action.workers.getWorkerList.list,
        total: action.workers.getWorkerList.total
      };
    case workerConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case workerConstants.GET_ALL_WORKER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.GET_ALL_WORKER_SUCCESS:
      return {
        ...state,
        getAllWorker: action.workers.getAllWorker
      };
    case workerConstants.GET_ALL_WORKER_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case workerConstants.UPDATE_WORKER_PASSWORD_REQUEST:
      return {
        ...state
      };
    case workerConstants.UPDATE_WORKER_PASSWORD_SUCCESS:
      return {
        ...state,
        addWorkerSuccess: true
      };
    case workerConstants.UPDATE_WORKER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case workerConstants.GETALL_WORKER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.GETALL_WORKER_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.workers.listOfNotification.list,
        listOfNotificationtotal: action.workers.listOfNotification.total
      };
    case workerConstants.GETALL_WORKER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case workerConstants.UPDATE_WORKER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.UPDATE_WORKER_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case workerConstants.UPDATE_WORKER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case workerConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.workers.restaurantDetails
      };
    case workerConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case workerConstants.ADD_RESTAURANT_WORKER_REQUEST:
      return {
        ...state
      };
    case workerConstants.ADD_RESTAURANT_WORKER_SUCCESS:
      return {
        ...state,
        addWorkerSuccess: true
      };
    case workerConstants.ADD_RESTAURANT_WORKER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case workerConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case workerConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case workerConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case workerConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case workerConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case workerConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case workerConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case workerConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case workerConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case workerConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case workerConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case workerConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case workerConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case workerConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case workerConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case workerConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.workers.statics
      };
    case workerConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}