import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  FormControl,
  // FormControlLabel,
  FormLabel,
  // InputLabel,
  // RadioGroup,
  // Radio,
  Select,
  MenuItem,
  // Input,
} from "@material-ui/core";
import DatePicker, { } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewJobDialog from "./components/ViewJobDialog/ViewJobDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { jobActions, landlordActions, joblandlordActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
import { designationActions } from "../../_actions";
const theme = createMuiTheme();
const dmID = "6019c8929fba3c2d3b9dad25";
const pmID = "6019c001d78e4b27bd2ee187";
const format = "YYYY-MM-DD";
const start = "";
const end = "";
class JobManagement extends Component {
  constructor(props) {
    document.title = "ATS | Administrator";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnDateSelect = this.handleOnDateSelect.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      jobCreateModal: false,
      updatejobCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
      updateformData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
      designation: {},
      landlords: {},
      selectedColoumn: "job_title",
      selectedDesignation: "select",
      startDate: "",
      endDate: "",
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
      selectField: "",
      jobs: {
        items: [],
        total: 0,
      },
      filteredJobs: {
        items: [],
        total: 0,
      },
      paginatedJobs: {
        items: [],
        total: 0,
      },
    };
  }

  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.getJobList(data));

    // let data1 = {
    //   keyWord: "",
    // };
    this.props.dispatch(designationActions.getDesignationList());

    let data2 = {
      keyWord: "",
      roll_id: 0,
    };
    this.props.dispatch(landlordActions.getLandlordList(data2));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.jobs.addJobSuccess) {
      return {
        ...nextProps,
        formData: {
          name: "",
          address: "",
          lat: "",
          desc: "",
          long: "",
          mobile: "",
          rating: "",
          logo: "",
          openTime: "",
          closeTime: "",
          countryCode: "",
          type: "",
        },
        jobCreateModal: false,
        updatejobCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (this.props.jobs) {
        const { items, total } = this.props.jobs;
        this.setState({
          jobs: { items, total },
          filteredJobs: { items, total },
          paginatedJobs: { items: items?.slice(0, 10) },
        });
      }
    }
  }

  handleClick = (offset, page, keyWord) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        offset,
        page,
        paginatedJobs: {
          items: this.state.filteredJobs.items.slice(
            offset,
            offset + this.state.size,
          ),
          total: 10,
        },
      };
    });

    // console.log("offset", offset);
    // console.log("page", page);
    // let data;
    // if (this.state.startDate && this.state.endDate) {
    //   data = {
    //     startDate: this.state.startDate,
    //     endDate: this.state.endDate,
    //     pageNo: page,
    //     size: this.state.size,
    //   };
    // } else {
    //   data = {
    //     columnName: this.state.selectedColoumn,
    //     keyWord: this.state.keyWord,
    //     pageNo: page,
    //     size: this.state.size,
    //   };
    // }

    // this.props.dispatch(jobActions.getJobList(data));
  };

  handleClickSearch = (offset, page) => {
    this.setState({ offset, page });
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          searchInput: this.state.searchInput,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          pageNo: page,
          size: this.state.size,
        };
        console.log('datadata___________', data);
        this.props.dispatch(jobActions.getJobList(data));
      }
      else {
        let data = {
          searchInput: this.state.searchInput,
          keyWord: this.state.keyWord,
          pageNo: page,
          size: this.state.size,
        };
        this.props.dispatch(jobActions.getJobList(data));
      }

    }

    else {
      let data = {
        keyWord: this.state.keyWord,
        pageNo: page,
        size: this.state.size,
      };
      this.props.dispatch(jobActions.getJobList(data));
    }
  };

  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let filteredItems;
    if (value !== "" && value !== undefined) {
      filteredItems = this.state.jobs.items.filter((item) => {
        // console.log("itemmmmmm", item);
        const { job_title, job_id } = item;
        return `${job_title}-${job_id}`
          .toLowerCase()
          .includes(value.trim().toLowerCase());
      });
      // console.log("filteredJobs", filteredItems);
    } else {
      filteredItems = Object.assign([], this.state.jobs.items);
    }

    this.setState({
      filteredJobs: { items: filteredItems, total: filteredItems.length },
      paginatedJobs: {
        items: filteredItems.slice(0, this.state.size),
        total: 10,
      },
    });
  }
  // handleOpenCreateModal(data) {
  //   //   let req = {
  //   //     "jobId": "62f98a7436ea3b3f3d83c72f",
  //   //     "timeData":[
  //   //        {
  //   //     "onSiteTime":1660643405,
  //   //     "offSiteTime":1660643605
  //   //       },
  //   //       {
  //   //     "onSiteTime":1660643805,
  //   //     "offSiteTime":1660644205
  //   //       },
  //   //       {
  //   //     "onSiteTime":1660644305,
  //   //     "offSiteTime":1660644405
  //   //         }	
  //   //   ]
  //   // }
  //     let req = {
  //       "jobId": data.id,
  //       "timeData": data.timeData
  //     }
  //     console.log("table k row se kya data aa rha h:::", data);
  //     console.log("99999999999999999999999", req);
  //     this.setState({ jobCreateModal: true, viewRowData: data });
  //     this.props.dispatch(landlordActions.getTimeData(req));
  //   }

  handleOpenCreateModal(data) {
    let req = {
      "jobId": data.id,
    }
    this.setState({ jobCreateModal: true, viewRowData: data });
    // this.props.dispatch(landlordActions.getTimeData(req));
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updatejobCreateModal: true, updateformData: data });
  }
  handleClose() {
    this.setState({ jobCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatejobCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };
  handleSubmit = () => {
    let reqData = {
      firstName: this.state.formData.firstName,
      email: this.state.formData.email,
      password: this.state.formData.password,
      lastName: this.state.formData.lastName,
      gender: this.state.formData.gender,
      dob: this.state.formData.dob,
      doj: this.state.formData.doj,
      mobile: this.state.formData.mobile,
      address: this.state.formData.address,
      height: this.state.formData.height,
      weight: this.state.formData.weight,
    };
    this.props.dispatch(jobActions.addJob(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      firstName: this.state.updateformData.firstName,
      email: this.state.updateformData.email,
      lastName: this.state.updateformData.lastName,
      gender: this.state.updateformData.gender,
      dob: this.state.updateformData.dob,
      doj: this.state.updateformData.doj,
      mobile: this.state.updateformData.mobile,
      address: this.state.updateformData.address,
      height: this.state.updateformData.height,
      weight: this.state.updateformData.weight,
    };

    this.props.dispatch(jobActions.updateJob(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      jobActions.uploadImage(event.target.files[event.target.files.length - 1]),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.deleteJob(tempdata));
  }
  onDisable(data) {
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.disableJob(tempdata));
  }

  onChangeHandler(event) {
    const { value } = event.target;
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedColoumn: value,
          keyWord: "",
          startDate: "",
          endDate: "",
          selectField: "",
        };
      },

      () => {
        // if (this.state.selectedColoumn !== "date_filter") {
        //   let data = {
        //     pageNo: 1,
        //     size: this.state.size,
        //     columnName: this.state.selectedColoumn,
        //     keyWord: this.state.keyWord,
        //   };
        //   this.props.dispatch(jobActions.getJobList(data));
        // }
      },
    );
    this.props.dispatch(jobActions.getJobList());
  }

  handleOnDateSelect(date, type) {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },
        () => {
          if (this.state.startDate && this.state.endDate) {
            let data = {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              // pageNo: 1,
              // size: this.state.size,
            };
            this.props.dispatch(jobActions.getJobList(data));
          }
        },
      );
    } else {
      this.props.dispatch(jobActions.getJobList());
      // this.props.dispatch(designationActions.getDesignationList());
    }
  }

  handleOnSelectChange(event) {
    const { value } = event.target;
    let data = {
      columnName: this.state.selectedColoumn,
      keyWord: value,
      // pageNo: 1,
      // size: this.state.size,
    };
    this.props.dispatch(jobActions.getJobList(data));
    this.setState((prevState) => {
      return {
        ...prevState,
        selectField: value,
        offset: 0,
      };
    });
  }



  handleOnDateSelectSearch = (date, type) => {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },

      );
    }
  }

  inputChangeSearch = (e) => {
    e.preventDefault();
    console.log('inputChangeSearch_called');
    let { searchInput } = this.state
    let { name, value } = e.target;
    searchInput[name] = value
    this.setState(searchInput);
  }
  searchJob = () => {
    if (this.state.searchInput) {
      if (this.state.startDate && this.state.endDate) {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          searchInput: this.state.searchInput,
        };
        console.log('datadatadatadatadatadatadatadatadatadatadatadata', data);
        this.props.dispatch(jobActions.getJobList(data));
      } else {
        let data = {
          keyWord: "",
          pageNo: 1,
          size: this.state.size,
          searchInput: this.state.searchInput,
          // columnName: "job_title",
        };
        this.props.dispatch(jobActions.getJobList(data));
      }

    }
    else {
      let data = {
        keyWord: "",
        pageNo: 1,
        size: this.state.size,
        // searchInput: this.state.searchInput,
        // columnName: "job_title",
      };
      this.props.dispatch(jobActions.getJobList(data));
    }
    console.log('000000000000000000');
  }

  reset = () => {
    this.setState({
      searchInput: {
        searchTitle: '',
        searchJobType: '',
        searchAddr: '',
        searchDesig: '',
      },
    })
    this.setState({ startDate: "" })
    this.setState({ endDate: "" })
    let data = {
      keyWord: this.state.keyWord,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.getJobList(data));
  }



  render() {
    let { jobs, classes, landlords, designation } = this.props;
    let { onOffItems } = landlords;
    let {
      // items, total, 
      imageUrl } = jobs;
    let { items, total, } = designation;


    // console.log("2222222222222222222", this.state.landlords)
    // const { items, total } = this.state.filteredJobs;
    // console.log("jobs", this.state.jobs);
    // console.log("filteredJobs", this.state.filteredJobs);
    console.log("this.state.searchInput______________________-", this.state.searchInput);

    return (
      <>
        <PageTitle title="Jobs" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchTitle" label="Job Title" variant="standard" name="searchTitle" value={this.state.searchInput.searchTitle} onChange={this.inputChangeSearch} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField id="searchAddr" label="Job Address" variant="standard" name="searchAddr" value={this.state.searchInput.searchAddr} onChange={this.inputChangeSearch} />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }}  >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          name="searchJobType"
                          labelId="select-label"
                          id="select"
                          value={this.state.searchInput.searchJobType}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Job Type</MenuItem>
                          <MenuItem value={dmID} key={dmID}> DM </MenuItem>
                          <MenuItem key={pmID} value={pmID}> PM </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className={classes.formControl} style={{ marginTop: '16px' }} >
                        <Select style={{ width: '205px' }}
                          select
                          variant="standard"
                          labelId="select-label"
                          id="select"
                          name="searchDesig"
                          value={this.state.searchInput.searchDesig}
                          onChange={this.inputChangeSearch}
                          displayEmpty
                        >
                          <MenuItem value="">Select Designation</MenuItem>
                          {items &&
                            items.length > 0 &&
                            items.map((designation) => {
                              return (
                                <MenuItem
                                  key={designation.id}
                                  value={designation.id}
                                >
                                  {designation.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >Start Date</FormLabel>
                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "startDate")}
                        className={classes.rmdpTopClass}
                        sort
                        zIndex={1500}
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={
                          this.state.endDate ? this.state.endDate : new Date()
                        }
                      />
                    </Grid>
                    <Grid item md={5} xs={12} >
                      <FormLabel style={{ fontSize: "13px" }} >End Date </FormLabel>
                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) => this.handleOnDateSelectSearch(date, "endDate")}
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={new Date()}
                        minDate={this.state.startDate && this.state.startDate}
                        style={{ height: "26px" }}
                      />
                    </Grid>
                  </Grid>


                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Button style={{ marginRight: "15px" }} variant="contained" onClick={this.searchJob}>Search</Button>
                      <Button variant="contained" onClick={this.reset}>Reset</Button>
                    </Grid>
                  </Grid>                </Grid>
                <Grid item sm />
                {this.state.selectedColoumn === "date_filter" && (
                  <Grid item className={classes.dateBox}>
                    <Grid item className={classes.dateStyle}>
                      <FormLabel
                        style={{ marginRight: "20px", fontSize: "13px" }}
                      >
                        Start Date
                      </FormLabel>

                      <DatePicker
                        value={this.state.startDate}
                        onChange={(date) =>
                          this.handleOnDateSelect(date, "startDate")
                        }
                        className={classes.rmdpTopClass}
                        sort
                        zIndex={1500}
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={
                          this.state.endDate ? this.state.endDate : new Date()
                        }
                        style={{ height: "26px" }}
                      />
                    </Grid>
                    <Grid item className={classes.dateStyle}>
                      <FormLabel
                        style={{ marginRight: "20px", fontSize: "13px" }}
                      >
                        End Date
                      </FormLabel>

                      <DatePicker
                        value={this.state.endDate}
                        onChange={(date) =>
                          this.handleOnDateSelect(date, "endDate")
                        }
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        plugins={[<DatePanel />]}
                        maxDate={new Date()}
                        minDate={this.state.startDate && this.state.startDate}
                        style={{ height: "26px" }}
                      />
                    </Grid>
                  </Grid>
                )}

                {this.state.selectedColoumn === "designation_name" && (
                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "33px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <Select
                        select
                        variant="standard"
                        labelId="select-label"
                        id="select"
                        value={this.state.selectField}
                        onChange={this.handleOnSelectChange}
                        displayEmpty
                      >
                        <MenuItem value={""}>Please Select</MenuItem>
                        {this.state.designation.items &&
                          this.state.designation.items.length > 0 &&
                          this.state.designation.items.map((designation) => {
                            return (
                              <MenuItem
                                key={designation.id}
                                value={designation.id}
                              >
                                {designation.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.state.selectedColoumn === "landlord_name" && (
                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "33px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <Select
                        select
                        variant="standard"
                        labelId="select-label"
                        id="select"
                        value={this.state.selectField}
                        onChange={this.handleOnSelectChange}
                        displayEmpty
                      >
                        <MenuItem value={""}>Please Select</MenuItem>
                        {this.state.landlords.landlordListItems &&
                          this.state.landlords.landlordListItems.length > 0 &&
                          this.state.landlords.landlordListItems.map((landlords) => {
                            return (
                              <MenuItem key={landlords.id} value={landlords.id}>
                                {landlords.contact_name
                                  ? landlords.contact_name
                                  : "-"}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {/* <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search job title/id"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    value={this.state.keyWord}
                    margin="normal"
                  />
                </Grid> */}
              </Grid>

              {this.state.filteredJobs.total &&
                this.state.filteredJobs.total > 0 ? (
                <>
                  <Table
                    data={this.state.paginatedJobs?.items}
                    offset={this.state.offset}
                    handleOpenCreateModal={this.handleOpenCreateModal}
                    onRowClick={this.onRowClick}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {this.state.filteredJobs.total &&
                this.state.filteredJobs.total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={this.state.filteredJobs.total}
                    onClick={(e, offset, page) =>
                      this.handleClickSearch(offset, page, this.state.keyWord)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        {/* <AddJobDialog
          jobCreateModal={this.state.jobCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        /> */}
        <ViewJobDialog
          jobCreateModal={this.state.jobCreateModal}
          classes={classes}
          onOffItems={onOffItems}
          imageUrl={imageUrl}
          // data={items}
          // formData={this.state.formData}
          viewRowData={this.state.viewRowData}
          // handleSubmit={this.handleSubmit}
          // handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        />
        {/* <UpdateJobDialog
          updateuserCreateModal={this.state.updatejobCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
        /> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { jobs, designation, landlords, } = state;
  return {
    jobs,
    designation,
    landlords,

  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(JobManagement)),
);
