// import React from "react";
// import {
//   Dialog,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Container,
//   CssBaseline,
//   Grid,
//   Typography,
// } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import { Button } from "../../../../components/Wrappers/Wrappers";

// export default function UpdatePasswordDialog(props) {
//   let {
//     classes,
//     updatePwdformData,
//     updateuserCreateModal,
//     updatepwdhandleChangeInput,
//     updatepwdhandleSubmit,
//     updatePwdhandleClose,
//   } = props;

//   return (
//     <Dialog
//       open={updateuserCreateModal}
//       onClose={updatePwdhandleClose}
//     >
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             onClick={updatePwdhandleClose}
//             aria-label="close"
//           >
//             <CloseIcon />
//           </IconButton>
//           <Typography variant="h6" className={classes.title}>
//             Update Password
//           </Typography>
//           <Button color="inherit" onClick={updatePwdhandleClose}>
//             Cancel
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <div className={classes.paper}>
//           <Typography component="h1" variant="h5">
//             Update Password
//           </Typography>

//           <ValidatorForm onSubmit={updatepwdhandleSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={12}>
//                 <TextValidator
//                   label="Password*"
//                   id="password"
//                   fullWidth
//                   onChange={updatepwdhandleChangeInput}
//                   name="password"
//                   type="password"
//                   value={updatePwdformData.password}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>

//               <Grid item xs={12}></Grid>
//             </Grid>
//             <Button
//               fullWidth
//               variant="contained"
//               color="primary"
//               type="submit"
//               className={classes.submit}
//             >
//               Save
//             </Button>
//           </ValidatorForm>
//         </div>
//       </Container>
//     </Dialog>
//   );
// }


import React from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Grid,
  CssBaseline
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const ResponsiveUpdatePasswordDialog = ({ updateuserCreateModal, updatePwdhandleClose, updatepwdhandleChangeInput, updatepwdhandleSubmit, updatePwdformData }) => {
  return (
    <Dialog
      open={updateuserCreateModal}
      onClose={updatePwdhandleClose}
      fullWidth
      maxWidth="xs"
    >
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={updatePwdhandleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
          <Typography component="h1" variant="h5">
            Update Password
          </Typography>

          <ValidatorForm onSubmit={updatepwdhandleSubmit} style={{ width: '100%', marginTop: '16px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  label="Password*"
                  id="password"
                  fullWidth
                  onChange={updatepwdhandleChangeInput}
                  name="password"
                  type="password"
                  value={updatePwdformData.password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: '24px 0 16px', color: "#fff", fontWeight: 700 }}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
};

export default ResponsiveUpdatePasswordDialog;
