import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import CircleIcon from '@mui/icons-material/Circle';
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 1024,
  },
});

function TableComponent(props) {
  let { data, offset, classes, handleOpenViewCreateModal, deleteJob, workerDeleteOpenModal, workerAddOpenModal } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#8093FE" }}>
            <TableRow>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>S. No.</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Status</TableCell>
              <TableCell style={{ width: "20%", color: "#fff", fontWeight: 700 }}>Job Title</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Job Id</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Designation</TableCell>
              <TableCell style={{ width: "25%", color: "#fff", fontWeight: 700 }}>Address</TableCell>
              <TableCell style={{ width: "25%", color: "#fff", fontWeight: 700 }}>Add Internal Staff</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Delete Internal Staff</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Action</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Delete Job</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {(tempdata && tempdata.start_date == 0) || (tempdata && tempdata.start_date == '') ? <><CircleIcon style={{ color: 'red', width: '12px' }} /></> : <><CircleIcon style={{ color: 'green', width: '12px' }} /></>}

                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.job_title}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.jobType_Id && tempdata.jobType_Id.id
                    ? tempdata.job_id
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.designation_Id && tempdata.designation_Id.name
                    ? tempdata.designation_Id.name
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata && tempdata.location_id
                    ? tempdata.location_id.linecomplete
                    : "-"}
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {!tempdata.isContractor ?
                    <Button
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: "5px", backgroundColor: "#81C4EA" }}
                      onClick={() => workerAddOpenModal(tempdata)}
                    >
                      Add Internal Staff
                    </Button>
                    :
                    null
                  }
                </TableCell>

                <TableCell style={{ textAlign: "left" }}>
                  {!tempdata.isContractor ?
                    <Button
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{ marginRight: "5px", backgroundColor: "#ED776B" }}
                      onClick={() => workerDeleteOpenModal(tempdata)}
                    >
                      Delete Internal Staff
                    </Button>
                    :
                    null
                  }
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px", backgroundColor: "#67C587" }}
                    onClick={() => handleOpenViewCreateModal(tempdata)}
                  >
                    More
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px", backgroundColor: "#FF4545" }}
                    onClick={() => deleteJob(tempdata)}
                  >
                    Delete Job
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
