import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";



export default function AddWorkerDialog(props) {

  let { classes, formData, workerCreateModal, handleClose, handleChangeInput, handleSubmit } = props;
  //console.log("workerCreateModal  ",workerCreateModal);

  return (
    <Dialog fullScreen open={workerCreateModal} onClose={handleClose} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Package
      </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
      </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Package
        </Typography>

          <ValidatorForm

            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>

                <TextValidator
                  label="Package Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="packName"
                  id="packName"
                  value={formData.packName}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Limit*"
                  id="limit"
                  fullWidth
                  onChange={handleChangeInput}
                  name="limit"
                  value={formData.limit}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Description*"
                  id="desc"
                  fullWidth
                  onChange={handleChangeInput}
                  name="desc"
                  value={formData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="password*"
                  id="password"
                  fullWidth
                  onChange={handleChangeInput}
                  name="password"
                  value={formData.password}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="roll_id*"
                  id="roll_id"
                  fullWidth
                  onChange={handleChangeInput}
                  name="roll_id"
                  value={formData.roll_id}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12}>
              <TextValidator
                label="gender*"
                id="gender"
                fullWidth
                onChange={handleChangeInput}
                name="gender"
                value={formData.gender}
                validators={['required']}
                errorMessages={['this field is required']}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="dob*"
                id="dob"
                fullWidth
                onChange={handleChangeInput}
                name="dob"
                value={formData.dob}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextValidator
                label="doj*"
                id="doj"
                fullWidth
                onChange={handleChangeInput}
                name="doj"
                value={formData.doj}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <TextValidator
                  label="mobile*"
                  id="mobile"
                  fullWidth
                  onChange={handleChangeInput}
                  name="mobile"
                  value={formData.mobile}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="address*"
                  id="address"
                  fullWidth
                  onChange={handleChangeInput}
                  name="address"
                  value={formData.address}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />

              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
            <TextValidator
                label="height*"
                id="height"
                fullWidth
                onChange={handleChangeInput}
                name="height"
                value={formData.height}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextValidator
                label="weight*"
                id="weight"
                fullWidth
                onChange={handleChangeInput}
                name="weight"
                value={formData.weight}
                validators={['required']}
                errorMessages={['this field is required']}
                />
              
            </Grid> */}
              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
          </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
