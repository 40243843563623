import React from "react";
import { Table, TableRow, TableHead, TableBody, TableCell, } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { sortItems } from "../../../../_helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 1024,
  },
});

function TableComponent(props) {
  let { data, offset, classes, handleOpenViewCreateModal } = props;
  let sortedData = {};
  if (data) {
    sortedData = sortItems("asc", "company_name", data);
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#8093FE" }}>
            <TableRow>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>S. No.</TableCell>
              <TableCell style={{ width: "20%", color: "#fff", fontWeight: 700 }}>Contact Name</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Company Name</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Phone</TableCell>
              <TableCell style={{ width: "25%", color: "#fff", fontWeight: 700 }}>Contractor Email</TableCell>
              <TableCell style={{ width: "15%", color: "#fff", fontWeight: 700 }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((tempdata, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.contact_name ? tempdata.contact_name : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.company_name ? tempdata.company_name : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.contact ? tempdata.contact : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal" style={{ color: "#898CAF" }}>
                  {tempdata.email ? tempdata.email : "-"}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleOpenViewCreateModal(tempdata)}
                  >
                    More Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
