// import React from "react";
// import {
//   Dialog,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Container,
//   CssBaseline,
//   Grid,
//   Typography,
//   FormControl,
//   MenuItem,
// } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
// import {
//   ValidatorForm,
//   TextValidator,
//   SelectValidator,
// } from "react-material-ui-form-validator";
// import { Button } from "../../../../components/Wrappers/Wrappers";

// export default function UpdateInternalStaffDialog(props) {
//   let {
//     classes,
//     updateformData,
//     updateuserCreateModal,
//     updatehandleClose,
//     updatehandleChangeInput,
//     updatehandleSubmit,
//     landlords,
//   } = props;

//   return (
//     <Dialog open={updateuserCreateModal} onClose={updatehandleClose}>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             onClick={updatehandleClose}
//             aria-label="close"
//           >
//             <CloseIcon />
//           </IconButton>
//           <Typography variant="h6" className={classes.title}>
//             Update Internal Staff
//           </Typography>
//           <Button color="inherit" onClick={updatehandleClose}>
//             Cancel
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <div className={classes.paper}>
//           <Typography component="h1" variant="h5">
//             Update Internal Staff
//           </Typography>

//           <ValidatorForm onSubmit={updatehandleSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={12}>
//                 <TextValidator
//                   label="First Name*"
//                   fullWidth
//                   onChange={updatehandleChangeInput}
//                   name="first_name"
//                   id="first_name"
//                   value={updateformData.first_name}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12}>
//                 <TextValidator
//                   label="Last Name*"
//                   id="last_name"
//                   fullWidth
//                   onChange={updatehandleChangeInput}
//                   name="last_name"
//                   value={updateformData.last_name}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12}>
//                 <TextValidator
//                   label="Email*"
//                   id="email"
//                   fullWidth
//                   onChange={updatehandleChangeInput}
//                   name="email"
//                   disabled={true}
//                   value={updateformData.email}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextValidator
//                   label="Contact No.*"
//                   id="contact"
//                   fullWidth
//                   onChange={updatehandleChangeInput}
//                   name="contact"
//                   value={updateformData.contact}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextValidator
//                   label="Address*"
//                   id="address"
//                   fullWidth
//                   onChange={updatehandleChangeInput}
//                   name="address"
//                   value={updateformData.address}
//                   validators={["required"]}
//                   errorMessages={["this field is required"]}
//                 />
//               </Grid>
//               <Grid item xs={12}></Grid>
//             </Grid>
//             <Button
//               fullWidth
//               variant="contained"
//               color="primary"
//               type="submit"
//               className={classes.submit}
//             >
//               Save
//             </Button>
//           </ValidatorForm>
//         </div>
//       </Container>
//     </Dialog>
//   );
// }

import React from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Container,
  Grid,
  CssBaseline
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const ResponsiveUpdateDialog = ({ updateuserCreateModal, updatehandleClose, updatehandleChangeInput, updatehandleSubmit, updateformData }) => {
  return (
    <Dialog
      open={updateuserCreateModal}
      onClose={updatehandleClose}
      fullWidth
      maxWidth="xs"
    >
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={updatehandleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
          <Typography component="h1" variant="h5">
            Update Internal Staff
          </Typography>

          <ValidatorForm onSubmit={updatehandleSubmit} style={{ width: '100%', marginTop: '16px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="First Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="first_name"
                  id="first_name"
                  value={updateformData.first_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Last Name*"
                  id="last_name"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="last_name"
                  value={updateformData.last_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Email*"
                  id="email"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="email"
                  disabled={true}
                  value={updateformData.email}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Contact No.*"
                  id="contact"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="contact"
                  value={updateformData.contact}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Address*"
                  id="address"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="address"
                  value={updateformData.address}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: '24px 0 16px', color: "white", fontWeight: 700 }}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
};

export default ResponsiveUpdateDialog;
